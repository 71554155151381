import React, { useState, useEffect } from "react";
import { Input } from "../../../../components";
import { Radio } from "antd";
import "./style.scss";
import {
  buildEffectDate,
  buildExpireDate,
  buildValueGT,
  buildValueLT,
} from "../../../../utils/build-logic";
import { STATUS_SECTION_APPROVE, DOC_MAX_LIMIT } from "@assets/const/status";
import { mapFieldIM } from "@utils/form-recog";

export default function InlandMarineForm(props) {
  const { docIndex, franchisorData } = props;

  const [formVal, setFormVal] = useState(handleGetFormVal());

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    if (franchiseeData?.finalData?.im_form_status === STATUS_SECTION_APPROVE) {
      const finalData = franchiseeData.finalData;

      return {
        im_limit: finalData.im_limit,
        im_limit_per_item: finalData.im_limit_per_item,
        im_business_interuption: finalData.im_business_interuption,
        im_policy_number: finalData.im_policy_number,
        im_effective_date: finalData.im_effective_date,
        im_expire_date: finalData.im_expire_date,
      };
    }

    if (docIndex >= 0 && docIndex !== DOC_MAX_LIMIT) {
      const data = franchiseeData?.recogData[docIndex][0]?.fields;
      if (data) {
        return mapFieldIM(data);
      }
    }

    return {};
  }

  const [loading, setLoading] = useState(false);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  return (
    <div className="report-im-content">
      <div className="report-im-content-analysis">
        <label>
          <b>Analysis:</b>
        </label>
        {buildEffectDate(
          formVal.im_effective_date,
          franchisorData?.im_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}

        {buildExpireDate(
          formVal.im_expire_date,
          franchisorData.im_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}

        {buildValueGT(
          formVal.im_limit,
          franchisorData.im_limit,
          franchisorData?.im_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}

        {buildValueGT(
          formVal.im_limit_per_item,
          franchisorData.im_limit_per_item,
          franchisorData.im_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}

        {buildValueLT(
          formVal.im_business_interuption,
          franchisorData.im_business_interuption,
          franchisorData.im_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
      </div>

      <div className="form-main">
        <div className="panel-left">
          <div className="alert-container">
            <h4>POLICY NUMBER: {formVal.im_policy_number}</h4>
            <h4>EFFECTIVE DATE: {formVal.im_effective_date}</h4>
            {buildEffectDate(
              formVal.im_effective_date,
              franchisorData?.im_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
            <h4>EXPIRATION DATE: {formVal.im_expire_date}</h4>
            {buildExpireDate(
              formVal.im_expire_date,
              franchisorData.im_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
        <div className="panel-right">
          <div className="line-item-container">
            <Input
              title="EACH OCCURRENCE"
              placeholder="EACH OCCURRENCE"
              value={formVal.im_limit}
              onChange={(evt) => handleChange("im_limit", evt.target.value)}
            />
            {buildValueGT(
              formVal.im_limit,
              franchisorData.im_limit,
              franchisorData?.im_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="line-item-container">
            <Input
              title="AGGREGATE"
              placeholder="AGGREGATE"
              value={formVal.im_limit_per_item}
              onChange={(evt) =>
                handleChange("im_limit_per_item", evt.target.value)
              }
            />

            {buildValueGT(
              formVal.im_limit_per_item,
              franchisorData.im_limit_per_item,
              franchisorData.im_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>

          <div className="line-item-container">
            <Input
              title="DEDUCTIBLE"
              placeholder="DEDUCTIBLE"
              value={formVal.im_business_interuption}
              onChange={(evt) =>
                handleChange("im_business_interuption", evt.target.value)
              }
            />

            {buildValueLT(
              formVal.im_business_interuption,
              franchisorData.im_business_interuption,
              franchisorData.im_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
      </div>
      <div className="report-im-content-mask"></div>
    </div>
  );
}
