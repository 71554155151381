import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./style.scss";
import { Card } from "../../../components";
import moment from "moment";
import { post } from "../../../utils/axios";
import { useAppState } from "../../../context";
import { Gauge, Pie } from "@ant-design/plots";
import {
  INSURANCE_STATUS_NONE,
  INSURANCE_STATUS_COMPLIANT,
  INSURANCE_STATUS_IN_PLACE,
  INSURANCE_STATUS_NON_COMPLIANT,
  INSURANCE_STATUS_NON_VERIFIED
} from "@assets/const/status";
import { handleGetStatus } from "@utils/build-logic";
import {
  COLOR_COMPLIANT,
  COLOR_NONE,
  COLOR_NON_COMPLIANT,
  COLOR_IN_PLACE,
  COLOR_UN_VERIFIED,
  COLOR_PENDING
} from "@assets/const/theme";

export default function Overview() {
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { franchisorAdmin } = useAppState();
  const [report, setReport] = useState({
    countFranchisor: 0,
    countFranchisee: 0,
    countCoi: 0,
    countExpired: 0,
    countCompliant: 0,
    countNonCompliant: 0,
    countPending: 0,
    countUnderDevelopment: 0
  });
  const [graphData, setGraphData] = useState({
    liability: {
      compliant: 0,
      nonCompliant: 0,
      none: 0,
      unVerified: 0,
      inPlace: 0
    },
    auto: {
      compliant: 0,
      nonCompliant: 0,
      none: 0,
      unVerified: 0,
      inPlace: 0
    },
    umbrella: {
      compliant: 0,
      nonCompliant: 0,
      none: 0,
      unVerified: 0,
      inPlace: 0
    },
    workComp: {
      compliant: 0,
      nonCompliant: 0,
      none: 0,
      unVerified: 0,
      inPlace: 0
    },
    crime: {
      compliant: 0,
      nonCompliant: 0,
      none: 0,
      unVerified: 0,
      inPlace: 0
    },
    garage: {
      compliant: 0,
      nonCompliant: 0,
      none: 0,
      unVerified: 0,
      inPlace: 0
    },
    epli: {
      compliant: 0,
      nonCompliant: 0,
      none: 0,
      unVerified: 0,
      inPlace: 0
    },
    cyber: {
      compliant: 0,
      nonCompliant: 0,
      none: 0,
      unVerified: 0,
      inPlace: 0
    },
    proLiab: {
      compliant: 0,
      nonCompliant: 0,
      none: 0,
      unVerified: 0,
      inPlace: 0
    },
    polLiab: {
      compliant: 0,
      nonCompliant: 0,
      none: 0,
      unVerified: 0,
      inPlace: 0
    },
    im: {
      compliant: 0,
      nonCompliant: 0,
      none: 0,
      unVerified: 0,
      inPlace: 0
    },
    proIns: {
      compliant: 0,
      nonCompliant: 0,
      none: 0,
      unVerified: 0,
      inPlace: 0
    }
  });
  const [reportScore, setReportScore] = useState({});
  const [dateRange, setDateRange] = useState({
    startDay: "2020-01-01",
    endDay: moment().add(1, "day").format("YYYY-MM-DD")
  });

  useEffect(() => {
    if (franchisorAdmin?._id) {
      handleFetchReport();
      handleFetchReportScore();
      handleFetchFranchisee();
    }
  }, [dateRange, franchisorAdmin]);

  async function handleFetchReportScore() {
    try {
      const token = await getAccessTokenSilently();
      const result = await post(
        `admin/report-score`,
        {
          startDay: dateRange.startDay,
          endDay: dateRange.endDay,
          franchisorId: franchisorAdmin?._id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (result?.data?.data) {
        setReportScore(result?.data?.data[0]);
      }
    } catch (error) {
      console.log("load error ---", error);
    }
  }

  async function handleFetchReport() {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const result = await post(
        `admin/report`,
        {
          startDay: dateRange.startDay,
          endDay: dateRange.endDay,
          franchisorId: franchisorAdmin?._id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (result.status === 200) {
        const data = result.data;
        setReport({
          countFranchisor: data.franchisor[0]?.total[0]?.count || 0,
          countFranchisee: data.franchisee[0]?.total[0]?.count || 0,
          countCoi: data.franchisee[0]?.coi[0]?.count || 0,
          countExpired: data.expired[0]?.count || 0,
          countCompliant: data.franchisee[0]?.compliant[0]?.count || 0,
          countNonCompliant: data.franchisee[0]?.nonCompliant[0]?.count || 0,
          countPending: data.franchisee[0]?.pending[0]?.count || 0,
          countUnderDevelopment:
            data.franchisee[0]?.underDevelopment[0]?.count || 0
        });
      }
    } catch (err) {}
  }

  async function handleFetchFranchisee() {
    try {
      const token = await getAccessTokenSilently();
      const result = await post(
        `admin/franchisor/${franchisorAdmin?._id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (result.status === 200) {
        handleGetFranchiseeInfor(result.data);
      }
    } catch (error) {}
  }

  function handleGetFranchiseeInfor(data) {
    const { franchiseeList, requirement } = data;
    if (franchiseeList?.length > 0) {
      let graphDataUpdate = {
        liability: {
          compliant: 0,
          nonCompliant: 0,
          none: 0,
          unVerified: 0,
          inPlace: 0
        },
        auto: {
          compliant: 0,
          nonCompliant: 0,
          none: 0,
          unVerified: 0,
          inPlace: 0
        },
        umbrella: {
          compliant: 0,
          nonCompliant: 0,
          none: 0,
          unVerified: 0,
          inPlace: 0
        },
        workComp: {
          compliant: 0,
          nonCompliant: 0,
          none: 0,
          unVerified: 0,
          inPlace: 0
        },
        crime: {
          compliant: 0,
          nonCompliant: 0,
          none: 0,
          unVerified: 0,
          inPlace: 0
        },
        garage: {
          compliant: 0,
          nonCompliant: 0,
          none: 0,
          unVerified: 0,
          inPlace: 0
        },
        epli: {
          compliant: 0,
          nonCompliant: 0,
          none: 0,
          unVerified: 0,
          inPlace: 0
        },
        cyber: {
          compliant: 0,
          nonCompliant: 0,
          none: 0,
          unVerified: 0,
          inPlace: 0
        },
        proLiab: {
          compliant: 0,
          nonCompliant: 0,
          none: 0,
          unVerified: 0,
          inPlace: 0
        },
        polLiab: {
          compliant: 0,
          nonCompliant: 0,
          none: 0,
          unVerified: 0,
          inPlace: 0
        },
        im: {
          compliant: 0,
          nonCompliant: 0,
          none: 0,
          unVerified: 0,
          inPlace: 0
        },
        proIns: {
          compliant: 0,
          nonCompliant: 0,
          none: 0,
          unVerified: 0,
          inPlace: 0
        }
      };
      for (const item of franchiseeList) {
        const liabilityStatus = handleGetStatus(
          item?.finalData?.commercial_general_liability_form_status,
          item?.liabilityIssue,
          requirement?.commercial_general_liability_required
        );

        if (liabilityStatus === INSURANCE_STATUS_COMPLIANT) {
          graphDataUpdate.liability.compliant++;
        }
        if (liabilityStatus === INSURANCE_STATUS_NON_COMPLIANT) {
          graphDataUpdate.liability.nonCompliant++;
        }

        if (liabilityStatus === INSURANCE_STATUS_NON_VERIFIED) {
          graphDataUpdate.liability.unVerified++;
        }

        if (liabilityStatus === INSURANCE_STATUS_NONE) {
          graphDataUpdate.liability.none++;
        }

        if (liabilityStatus === INSURANCE_STATUS_IN_PLACE) {
          graphDataUpdate.liability.inPlace++;
        }

        const autoStatus = handleGetStatus(
          item?.finalData?.automotive_liability_form_status,
          item?.autoIssue,
          requirement?.automotive_liability_required
        );

        if (autoStatus === INSURANCE_STATUS_COMPLIANT) {
          graphDataUpdate.auto.compliant++;
        }
        if (autoStatus === INSURANCE_STATUS_NON_COMPLIANT) {
          graphDataUpdate.auto.nonCompliant++;
        }

        if (autoStatus === INSURANCE_STATUS_NON_VERIFIED) {
          graphDataUpdate.auto.unVerified++;
        }

        if (autoStatus === INSURANCE_STATUS_NONE) {
          graphDataUpdate.auto.none++;
        }

        if (autoStatus === INSURANCE_STATUS_IN_PLACE) {
          graphDataUpdate.auto.inPlace++;
        }

        const umbrellaStatus = handleGetStatus(
          item?.finalData?.umbrella_subrogration_form_status,
          item?.umbrellaIssue,
          requirement?.umbrella_subrogration_required
        );

        if (umbrellaStatus === INSURANCE_STATUS_COMPLIANT) {
          graphDataUpdate.umbrella.compliant++;
        }
        if (umbrellaStatus === INSURANCE_STATUS_NON_COMPLIANT) {
          graphDataUpdate.umbrella.nonCompliant++;
        }

        if (umbrellaStatus === INSURANCE_STATUS_NON_VERIFIED) {
          graphDataUpdate.umbrella.unVerified++;
        }

        if (umbrellaStatus === INSURANCE_STATUS_NONE) {
          graphDataUpdate.umbrella.none++;
        }

        if (umbrellaStatus === INSURANCE_STATUS_IN_PLACE) {
          graphDataUpdate.umbrella.inPlace++;
        }

        const workCompStatus = handleGetStatus(
          item?.finalData?.workers_compensation_form_status,
          item?.workCompIssue,
          requirement?.workers_compensation_required
        );

        if (workCompStatus === INSURANCE_STATUS_COMPLIANT) {
          graphDataUpdate.workComp.compliant++;
        }
        if (workCompStatus === INSURANCE_STATUS_NON_COMPLIANT) {
          graphDataUpdate.workComp.nonCompliant++;
        }

        if (workCompStatus === INSURANCE_STATUS_NON_VERIFIED) {
          graphDataUpdate.workComp.unVerified++;
        }

        if (workCompStatus === INSURANCE_STATUS_NONE) {
          graphDataUpdate.workComp.none++;
        }

        if (workCompStatus === INSURANCE_STATUS_IN_PLACE) {
          graphDataUpdate.workComp.inPlace++;
        }

        const crimeStatus = handleGetStatus(
          item?.finalData?.crime_form_status,
          item?.crimeIssue,
          requirement?.crime_required
        );

        if (crimeStatus === INSURANCE_STATUS_COMPLIANT) {
          graphDataUpdate.crime.compliant++;
        }
        if (crimeStatus === INSURANCE_STATUS_NON_COMPLIANT) {
          graphDataUpdate.crime.nonCompliant++;
        }

        if (crimeStatus === INSURANCE_STATUS_NON_VERIFIED) {
          graphDataUpdate.crime.unVerified++;
        }

        if (crimeStatus === INSURANCE_STATUS_NONE) {
          graphDataUpdate.crime.none++;
        }

        if (crimeStatus === INSURANCE_STATUS_IN_PLACE) {
          graphDataUpdate.crime.inPlace++;
        }

        const garageStatus = handleGetStatus(
          item?.finalData?.garage_form_status,
          item?.garageIssue,
          requirement?.garage_required
        );

        if (garageStatus === INSURANCE_STATUS_COMPLIANT) {
          graphDataUpdate.garage.compliant++;
        }
        if (garageStatus === INSURANCE_STATUS_NON_COMPLIANT) {
          graphDataUpdate.garage.nonCompliant++;
        }

        if (garageStatus === INSURANCE_STATUS_NON_VERIFIED) {
          graphDataUpdate.garage.unVerified++;
        }

        if (garageStatus === INSURANCE_STATUS_NONE) {
          graphDataUpdate.garage.none++;
        }

        if (garageStatus === INSURANCE_STATUS_IN_PLACE) {
          graphDataUpdate.garage.inPlace++;
        }

        const epliStatus = handleGetStatus(
          item?.finalData?.epli_form_status,
          item?.epliIssue,
          requirement?.epli_required
        );

        if (epliStatus === INSURANCE_STATUS_COMPLIANT) {
          graphDataUpdate.epli.compliant++;
        }
        if (epliStatus === INSURANCE_STATUS_NON_COMPLIANT) {
          graphDataUpdate.epli.nonCompliant++;
        }

        if (epliStatus === INSURANCE_STATUS_NON_VERIFIED) {
          graphDataUpdate.epli.unVerified++;
        }

        if (epliStatus === INSURANCE_STATUS_NONE) {
          graphDataUpdate.epli.none++;
        }

        if (epliStatus === INSURANCE_STATUS_IN_PLACE) {
          graphDataUpdate.epli.inPlace++;
        }

        const cyberStatus = handleGetStatus(
          item?.finalData?.cyber_form_status,
          item?.cyberIssue,
          requirement?.cyber_required
        );

        if (cyberStatus === INSURANCE_STATUS_COMPLIANT) {
          graphDataUpdate.cyber.compliant++;
        }
        if (cyberStatus === INSURANCE_STATUS_NON_COMPLIANT) {
          graphDataUpdate.cyber.nonCompliant++;
        }

        if (cyberStatus === INSURANCE_STATUS_NON_VERIFIED) {
          graphDataUpdate.cyber.unVerified++;
        }

        if (cyberStatus === INSURANCE_STATUS_NONE) {
          graphDataUpdate.cyber.none++;
        }

        if (cyberStatus === INSURANCE_STATUS_IN_PLACE) {
          graphDataUpdate.cyber.inPlace++;
        }

        const proLiabStatus = handleGetStatus(
          item?.finalData?.pro_liab_form_status,
          item?.proLiabIssue,
          requirement?.pro_liab_required
        );

        if (proLiabStatus === INSURANCE_STATUS_COMPLIANT) {
          graphDataUpdate.proLiab.compliant++;
        }
        if (proLiabStatus === INSURANCE_STATUS_NON_COMPLIANT) {
          graphDataUpdate.proLiab.nonCompliant++;
        }

        if (proLiabStatus === INSURANCE_STATUS_NON_VERIFIED) {
          graphDataUpdate.proLiab.unVerified++;
        }

        if (proLiabStatus === INSURANCE_STATUS_NONE) {
          graphDataUpdate.proLiab.none++;
        }

        if (proLiabStatus === INSURANCE_STATUS_IN_PLACE) {
          graphDataUpdate.proLiab.inPlace++;
        }

        const polLiabStatus = handleGetStatus(
          item?.finalData?.pol_liab_form_status,
          item?.polLiabIssue,
          requirement?.pol_liab_required
        );

        if (polLiabStatus === INSURANCE_STATUS_COMPLIANT) {
          graphDataUpdate.polLiab.compliant++;
        }
        if (polLiabStatus === INSURANCE_STATUS_NON_COMPLIANT) {
          graphDataUpdate.polLiab.nonCompliant++;
        }

        if (polLiabStatus === INSURANCE_STATUS_NON_VERIFIED) {
          graphDataUpdate.polLiab.unVerified++;
        }

        if (polLiabStatus === INSURANCE_STATUS_NONE) {
          graphDataUpdate.polLiab.none++;
        }

        if (polLiabStatus === INSURANCE_STATUS_IN_PLACE) {
          graphDataUpdate.polLiab.inPlace++;
        }

        const imStatus = handleGetStatus(
          item?.finalData?.im_form_status,
          item?.imIssue,
          requirement?.im_required
        );

        if (imStatus === INSURANCE_STATUS_COMPLIANT) {
          graphDataUpdate.im.compliant++;
        }
        if (imStatus === INSURANCE_STATUS_NON_COMPLIANT) {
          graphDataUpdate.im.nonCompliant++;
        }

        if (imStatus === INSURANCE_STATUS_NON_VERIFIED) {
          graphDataUpdate.im.unVerified++;
        }

        if (imStatus === INSURANCE_STATUS_NONE) {
          graphDataUpdate.im.none++;
        }

        if (imStatus === INSURANCE_STATUS_IN_PLACE) {
          graphDataUpdate.im.inPlace++;
        }

        const proInsStatus = handleGetStatus(
          item?.finalData?.pro_ins_form_status,
          item?.proInsIssue,
          requirement?.pro_ins_required
        );

        if (proInsStatus === INSURANCE_STATUS_COMPLIANT) {
          graphDataUpdate.proIns.compliant++;
        }
        if (proInsStatus === INSURANCE_STATUS_NON_COMPLIANT) {
          graphDataUpdate.proIns.nonCompliant++;
        }

        if (proInsStatus === INSURANCE_STATUS_NON_VERIFIED) {
          graphDataUpdate.proIns.unVerified++;
        }

        if (proInsStatus === INSURANCE_STATUS_NONE) {
          graphDataUpdate.proIns.none++;
        }

        if (proInsStatus === INSURANCE_STATUS_IN_PLACE) {
          graphDataUpdate.proIns.inPlace++;
        }
      }

      setGraphData(graphDataUpdate);
    }
  }

  const scoreConfig = {
    percent: (reportScore?.score || 0) / 100,
    type: "meter",
    innerRadius: 0.75,
    range: {
      ticks: [0, 1 / 3, 2 / 3, 1],
      color: [COLOR_NON_COMPLIANT, COLOR_PENDING, COLOR_COMPLIANT]
    },
    indicator: {
      pointer: {
        style: {
          stroke: "#D0D0D0"
        }
      },
      pin: {
        style: {
          stroke: "#D0D0D0"
        }
      }
    },
    statistic: {
      content: {
        style: {
          fontSize: "36px",
          lineHeight: "36px"
        }
      }
    }
  };

  const requestData = [
    {
      type: "Pending Document Upload",
      value: report?.countFranchisee - report?.countCoi
    },
    {
      type: "COI Document Uploaded",
      value: report?.countCoi
    }
  ];

  const commonConfig = {
    appendPadding: 10,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 10
      }
    },
    color: [COLOR_NON_COMPLIANT, COLOR_COMPLIANT],
    interactions: [
      {
        type: "element-selected"
      },
      {
        type: "element-active"
      }
    ],
    legend: {
      position: "bottom"
    },
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "overflow",
          textOverflow: "ellipsis"
        },
        content: ""
      }
    }
  };

  const requestConfig = {
    ...commonConfig,
    data: requestData
  };

  const liabilityData = [
    {
      type: "Compliant",
      value: graphData?.liability?.compliant
    },
    {
      type: "Non-Compliant",
      value: graphData?.liability?.nonCompliant
    },
    {
      type: "Document Missing",
      value: graphData?.liability?.none
    },
    {
      type: "In Place",
      value: graphData?.liability?.inPlace
    },
    {
      type: "Not-Verified",
      value: graphData?.liability?.unVerified
    }
  ];

  const liabilityConfig = {
    ...commonConfig,
    data: liabilityData,
    color: [
      COLOR_COMPLIANT,
      COLOR_NON_COMPLIANT,
      COLOR_NONE,
      COLOR_IN_PLACE,
      COLOR_UN_VERIFIED
    ]
  };

  const autoData = [
    {
      type: "Compliant",
      value: graphData?.auto?.compliant
    },
    {
      type: "Non-Compliant",
      value: graphData?.auto?.nonCompliant
    },
    {
      type: "Document Missing",
      value: graphData?.auto?.none
    },
    {
      type: "In Place",
      value: graphData?.auto?.inPlace
    },
    {
      type: "Not-Verified",
      value: graphData?.auto?.unVerified
    }
  ];

  const autoConfig = {
    ...commonConfig,
    data: autoData,
    color: [
      COLOR_COMPLIANT,
      COLOR_NON_COMPLIANT,
      COLOR_NONE,
      COLOR_IN_PLACE,
      COLOR_UN_VERIFIED
    ]
  };

  const workCompData = [
    {
      type: "Compliant",
      value: graphData?.workComp?.compliant
    },
    {
      type: "Non-Compliant",
      value: graphData?.workComp?.nonCompliant
    },
    {
      type: "Document Missing",
      value: graphData?.workComp?.none
    },
    {
      type: "In Place",
      value: graphData?.workComp?.inPlace
    },
    {
      type: "Not-Verified",
      value: graphData?.workComp?.unVerified
    }
  ];

  const workCompConfig = {
    ...commonConfig,
    data: workCompData,
    color: [
      COLOR_COMPLIANT,
      COLOR_NON_COMPLIANT,
      COLOR_NONE,
      COLOR_IN_PLACE,
      COLOR_UN_VERIFIED
    ]
  };

  const umbrellaData = [
    {
      type: "Compliant",
      value: graphData?.umbrella?.compliant
    },
    {
      type: "Non-Compliant",
      value: graphData?.umbrella?.nonCompliant
    },
    {
      type: "Document Missing",
      value: graphData?.umbrella?.none
    },
    {
      type: "In Place",
      value: graphData?.umbrella?.inPlace
    },
    {
      type: "Not-Verified",
      value: graphData?.umbrella?.unVerified
    }
  ];

  const umbrellaConfig = {
    ...commonConfig,
    data: umbrellaData,
    color: [
      COLOR_COMPLIANT,
      COLOR_NON_COMPLIANT,
      COLOR_NONE,
      COLOR_IN_PLACE,
      COLOR_UN_VERIFIED
    ]
  };

  const crimeData = [
    {
      type: "Compliant",
      value: graphData?.crime?.compliant
    },
    {
      type: "Non-Compliant",
      value: graphData?.crime?.nonCompliant
    },
    {
      type: "Document Missing",
      value: graphData?.crime?.none
    },
    {
      type: "In Place",
      value: graphData?.crime?.inPlace
    },
    {
      type: "Not-Verified",
      value: graphData?.crime?.unVerified
    }
  ];

  const crimeConfig = {
    ...commonConfig,
    data: crimeData,
    color: [
      COLOR_COMPLIANT,
      COLOR_NON_COMPLIANT,
      COLOR_NONE,
      COLOR_IN_PLACE,
      COLOR_UN_VERIFIED
    ]
  };

  const garageData = [
    {
      type: "Compliant",
      value: graphData?.garage?.compliant
    },
    {
      type: "Non-Compliant",
      value: graphData?.garage?.nonCompliant
    },
    {
      type: "Document Missing",
      value: graphData?.garage?.none
    },
    {
      type: "In Place",
      value: graphData?.garage?.inPlace
    },
    {
      type: "Not-Verified",
      value: graphData?.garage?.unVerified
    }
  ];

  const garageConfig = {
    ...commonConfig,
    data: garageData,
    color: [
      COLOR_COMPLIANT,
      COLOR_NON_COMPLIANT,
      COLOR_NONE,
      COLOR_IN_PLACE,
      COLOR_UN_VERIFIED
    ]
  };

  const epliData = [
    {
      type: "Compliant",
      value: graphData?.epli?.compliant
    },
    {
      type: "Non-Compliant",
      value: graphData?.epli?.nonCompliant
    },
    {
      type: "Document Missing",
      value: graphData?.epli?.none
    },
    {
      type: "In Place",
      value: graphData?.epli?.inPlace
    },
    {
      type: "Not-Verified",
      value: graphData?.epli?.unVerified
    }
  ];

  const epliConfig = {
    ...commonConfig,
    data: epliData,
    color: [
      COLOR_COMPLIANT,
      COLOR_NON_COMPLIANT,
      COLOR_NONE,
      COLOR_IN_PLACE,
      COLOR_UN_VERIFIED
    ]
  };

  const cyberData = [
    {
      type: "Compliant",
      value: graphData?.cyber?.compliant
    },
    {
      type: "Non-Compliant",
      value: graphData?.cyber?.nonCompliant
    },
    {
      type: "Document Missing",
      value: graphData?.cyber?.none
    },
    {
      type: "In Place",
      value: graphData?.cyber?.inPlace
    },
    {
      type: "Not-Verified",
      value: graphData?.cyber?.unVerified
    }
  ];

  const cyberConfig = {
    ...commonConfig,
    data: cyberData,
    color: [
      COLOR_COMPLIANT,
      COLOR_NON_COMPLIANT,
      COLOR_NONE,
      COLOR_IN_PLACE,
      COLOR_UN_VERIFIED
    ]
  };

  const proLiabData = [
    {
      type: "Compliant",
      value: graphData?.proLiab?.compliant
    },
    {
      type: "Non-Compliant",
      value: graphData?.proLiab?.nonCompliant
    },
    {
      type: "Document Missing",
      value: graphData?.proLiab?.none
    },
    {
      type: "In Place",
      value: graphData?.proLiab?.inPlace
    },
    {
      type: "Not-Verified",
      value: graphData?.proLiab?.unVerified
    }
  ];

  const proLiabConfig = {
    ...commonConfig,
    data: proLiabData,
    color: [
      COLOR_COMPLIANT,
      COLOR_NON_COMPLIANT,
      COLOR_NONE,
      COLOR_IN_PLACE,
      COLOR_UN_VERIFIED
    ]
  };

  const polLiabData = [
    {
      type: "Compliant",
      value: graphData?.polLiab?.compliant
    },
    {
      type: "Non-Compliant",
      value: graphData?.polLiab?.nonCompliant
    },
    {
      type: "Document Missing",
      value: graphData?.polLiab?.none
    },
    {
      type: "In Place",
      value: graphData?.polLiab?.inPlace
    },
    {
      type: "Not-Verified",
      value: graphData?.polLiab?.unVerified
    }
  ];

  const polLiabConfig = {
    ...commonConfig,
    data: polLiabData,
    color: [
      COLOR_COMPLIANT,
      COLOR_NON_COMPLIANT,
      COLOR_NONE,
      COLOR_IN_PLACE,
      COLOR_UN_VERIFIED
    ]
  };

  const imData = [
    {
      type: "Compliant",
      value: graphData?.im?.compliant
    },
    {
      type: "Non-Compliant",
      value: graphData?.im?.nonCompliant
    },
    {
      type: "Document Missing",
      value: graphData?.im?.none
    },
    {
      type: "In Place",
      value: graphData?.im?.inPlace
    },
    {
      type: "Not-Verified",
      value: graphData?.im?.unVerified
    }
  ];

  const imConfig = {
    ...commonConfig,
    data: imData,
    color: [
      COLOR_COMPLIANT,
      COLOR_NON_COMPLIANT,
      COLOR_NONE,
      COLOR_IN_PLACE,
      COLOR_UN_VERIFIED
    ]
  };

  const proInsData = [
    {
      type: "Compliant",
      value: graphData?.proIns?.compliant
    },
    {
      type: "Non-Compliant",
      value: graphData?.proIns?.nonCompliant
    },
    {
      type: "Document Missing",
      value: graphData?.proIns?.none
    },
    {
      type: "In Place",
      value: graphData?.proIns?.inPlace
    },
    {
      type: "Not-Verified",
      value: graphData?.proIns?.unVerified
    }
  ];

  const proInsConfig = {
    ...commonConfig,
    data: proInsData,
    color: [
      COLOR_COMPLIANT,
      COLOR_NON_COMPLIANT,
      COLOR_NONE,
      COLOR_IN_PLACE,
      COLOR_UN_VERIFIED
    ]
  };

  return (
    <div className="dashboard_overview">
      <Card title="Franchisee Analysis">
        <div className="dashboard_overview-wrapper">
          <div className="dashboard_overview-wrapper-section">
            <div className="dashboard_overview-wrapper-section-title">
              Average Compliance Score
            </div>
            <Gauge {...scoreConfig} />
          </div>
          <div className="dashboard_overview-wrapper-section">
            <div className="dashboard_overview-wrapper-section-title">
              COI Request Status
            </div>
            <Pie {...requestConfig} />
          </div>
        </div>
      </Card>
      <Card title="Coverage Analysis">
        <div className="dashboard_overview-content">
          {franchisorAdmin?.commercial_general_liability_required && (
            <div className="dashboard_overview-content-section">
              <div className="dashboard_overview-content-section-title">
                Liabilty
              </div>
              <Pie {...liabilityConfig} />
            </div>
          )}
          {franchisorAdmin?.automotive_liability_required && (
            <div className="dashboard_overview-content-section">
              <div className="dashboard_overview-content-section-title">
                Auto
              </div>
              <Pie {...autoConfig} />
            </div>
          )}
          {franchisorAdmin?.workers_compensation_required && (
            <div className="dashboard_overview-content-section">
              <div className="dashboard_overview-content-section-title">
                Workers Compensation
              </div>
              <Pie {...workCompConfig} />
            </div>
          )}
          {franchisorAdmin?.umbrella_subrogration_required && (
            <div className="dashboard_overview-content-section">
              <div className="dashboard_overview-content-section-title">
                Umbrella
              </div>
              <Pie {...umbrellaConfig} />
            </div>
          )}
          {franchisorAdmin?.crime_required && (
            <div className="dashboard_overview-content-section">
              <div className="dashboard_overview-content-section-title">
                Crime
              </div>
              <Pie {...crimeConfig} />
            </div>
          )}
          {franchisorAdmin?.garage_required && (
            <div className="dashboard_overview-content-section">
              <div className="dashboard_overview-content-section-title">
                Garage
              </div>
              <Pie {...garageConfig} />
            </div>
          )}
          {franchisorAdmin?.epli_required && (
            <div className="dashboard_overview-content-section">
              <div className="dashboard_overview-content-section-title">
                Epli
              </div>
              <Pie {...epliConfig} />
            </div>
          )}
          {franchisorAdmin?.cyber_required && (
            <div className="dashboard_overview-content-section">
              <div className="dashboard_overview-content-section-title">
                Cyber
              </div>
              <Pie {...cyberConfig} />
            </div>
          )}

          {franchisorAdmin?.pro_liab_required && (
            <div className="dashboard_overview-content-section">
              <div className="dashboard_overview-content-section-title">
                Professional Liability
              </div>
              <Pie {...proLiabConfig} />
            </div>
          )}

          {franchisorAdmin?.pol_liab_required && (
            <div className="dashboard_overview-content-section">
              <div className="dashboard_overview-content-section-title">
                Pollution Liability
              </div>
              <Pie {...polLiabConfig} />
            </div>
          )}

          {franchisorAdmin?.im_required && (
            <div className="dashboard_overview-content-section">
              <div className="dashboard_overview-content-section-title">
                Inland Marine
              </div>
              <Pie {...imConfig} />
            </div>
          )}

          {franchisorAdmin?.pro_ins_required && (
            <div className="dashboard_overview-content-section">
              <div className="dashboard_overview-content-section-title">
                Property Insurance
              </div>
              <Pie {...proInsConfig} />
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}
