import React, { useState, useEffect } from "react";
import "./style.scss";
import { Input } from "../../../../components";
import { Checkbox, Radio } from "antd";

import {
  FORM_CLAIMS_MADE,
  FORM_OCCURRENCE,
  FORM_POLICY,
  FORM_PROJECT,
  FORM_LOC,
} from "../../../../assets/const/form";
import { mapFieldLiability } from "../../../../utils/form-recog";
import {
  buildGeneralPolicy,
  buildGeneralAggregateLimit,
  buildGeneralLiabilityAdditionalInsurance,
  buildGeneralLiabilitySubrogrationWaved,
  buildEffectDate,
  buildExpireDate,
  buildValueGT,
} from "../../../../utils/build-logic";
import { STATUS_SECTION_APPROVE, DOC_MAX_LIMIT } from "@assets/const/status";

export default function LiabilityForm(props) {
  const { docIndex, franchisorData, franchiseeData } = props;

  const [formVal, setFormVal] = useState(handleGetFormVal());

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex, franchiseeData]);

  function handleGetFormVal() {
    if (
      franchiseeData?.finalData?.commercial_general_liability_form_status ===
      STATUS_SECTION_APPROVE
    ) {
      const finalData = franchiseeData.finalData;
      return {
        commercial_general_policy: finalData.commercial_general_policy,
        commercial_general_aggregate_limit:
          finalData.commercial_general_aggregate_limit,
        commercial_general_liability_additional_insurance:
          finalData.commercial_general_liability_additional_insurance,
        commercial_general_liability_subrogration_waved:
          finalData.commercial_general_liability_subrogration_waved,
        commercial_general_liability_each_occurance:
          finalData.commercial_general_liability_each_occurance,
        commercial_general_liability_damage_rented_premises:
          finalData.commercial_general_liability_damage_rented_premises,
        commercial_general_liability_medical_expenses:
          finalData.commercial_general_liability_medical_expenses,
        commercial_general_liability_personal_adv_injury:
          finalData.commercial_general_liability_personal_adv_injury,
        commercial_general_liability_general_aggregate:
          finalData.commercial_general_liability_general_aggregate,
        commercial_general_liability_comprop_acg:
          finalData.commercial_general_liability_comprop_acg,
        commercial_general_liability_policy_number:
          finalData.commercial_general_liability_policy_number,
        commercial_general_liability_effective_date:
          finalData.commercial_general_liability_effective_date,
        commercial_general_liability_expire_date:
          finalData.commercial_general_liability_expire_date,
      };
    }

    if (docIndex >= 0 && docIndex !== DOC_MAX_LIMIT) {
      const data = franchiseeData?.recogData[docIndex];
      if (data) {
        return mapFieldLiability(data);
      }
    }

    return {};
  }

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  return (
    <div className="report-liability-content">
      <div className="report-liability-content-analysis">
        <label>
          <b>Analysis:</b>
        </label>
        {buildGeneralPolicy(
          formVal.commercial_general_policy,
          franchisorData.commercial_general_policy,
          franchisorData.commercial_general_liability_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}

        {buildGeneralAggregateLimit(
          formVal.commercial_general_aggregate_limit,
          franchisorData.commercial_general_aggregate_limit,
          franchisorData.commercial_general_liability_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
        {buildGeneralLiabilityAdditionalInsurance(
          formVal.commercial_general_liability_additional_insurance,
          franchisorData.commercial_general_liability_additional_insurance,
          franchisorData.commercial_general_liability_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}

        {buildGeneralLiabilitySubrogrationWaved(
          formVal.commercial_general_liability_subrogration_waved,
          franchisorData.commercial_general_liability_subrogration_waved,
          franchisorData.commercial_general_liability_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}

        {buildEffectDate(
          formVal.commercial_general_liability_effective_date,
          franchisorData.commercial_general_liability_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}

        {buildExpireDate(
          formVal.commercial_general_liability_expire_date,
          franchisorData.commercial_general_liability_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}

        {buildValueGT(
          formVal.commercial_general_liability_each_occurance,
          franchisorData.commercial_general_liability_each_occurance,
          franchisorData.commercial_general_liability_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> EACH OCCURRENCE {item.title}
          </div>
        ))}

        {buildValueGT(
          formVal.commercial_general_liability_damage_rented_premises,
          franchisorData.commercial_general_liability_damage_rented_premises,
          franchisorData.commercial_general_liability_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> DAMAGE TO RENTED PERMISES {item.title}
          </div>
        ))}

        {buildValueGT(
          formVal.commercial_general_liability_medical_expenses,
          franchisorData.commercial_general_liability_medical_expenses,
          franchisorData.commercial_general_liability_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> MED EXP {item.title}
          </div>
        ))}

        {buildValueGT(
          formVal.commercial_general_liability_personal_adv_injury,
          franchisorData.commercial_general_liability_personal_adv_injury,
          franchisorData.commercial_general_liability_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> PERSONAL & ADV INJURY {item.title}
          </div>
        ))}

        {buildValueGT(
          formVal.commercial_general_liability_general_aggregate,
          franchisorData.commercial_general_liability_general_aggregate,
          franchisorData.commercial_general_liability_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> GENERAL AGGREGATE {item.title}
          </div>
        ))}

        {buildValueGT(
          formVal.commercial_general_liability_comprop_acg,
          franchisorData.commercial_general_liability_comprop_acg,
          franchisorData.commercial_general_liability_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> PRODUCTS - COMP / OP AGG {item.title}
          </div>
        ))}
      </div>

      <div className="form-main">
        <div className="panel-left">
          <div className="radio-container">
            <h5 className="radio-title">Commecial General Liability</h5>
            <Radio.Group
              onChange={(evt) =>
                handleChange("commercial_general_policy", evt.target.value)
              }
              value={formVal.commercial_general_policy}
            >
              <Radio value={FORM_OCCURRENCE}>Occurrence</Radio>
              <Radio value={FORM_CLAIMS_MADE}>Claims Made</Radio>
            </Radio.Group>
            {buildGeneralPolicy(
              formVal.commercial_general_policy,
              franchisorData.commercial_general_policy,
              franchisorData.commercial_general_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="radio-container">
            <h5 className="radio-title">GEN”L AGGREGATE LIMIT APPLIES PER:</h5>
            <Radio.Group
              onChange={(evt) =>
                handleChange(
                  "commercial_general_aggregate_limit",
                  evt.target.value
                )
              }
              value={formVal.commercial_general_aggregate_limit}
            >
              <Radio value={FORM_POLICY}>Policy</Radio>
              <Radio value={FORM_PROJECT}>Project</Radio>
              <Radio value={FORM_LOC}>Loc</Radio>
            </Radio.Group>
            {buildGeneralAggregateLimit(
              formVal.commercial_general_aggregate_limit,
              franchisorData.commercial_general_aggregate_limit,
              franchisorData.commercial_general_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="checkbox-container">
            <Checkbox
              checked={
                formVal.commercial_general_liability_additional_insurance
              }
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_additional_insurance",
                  evt.target.checked
                )
              }
            >
              Additional Insured
            </Checkbox>
            {buildGeneralLiabilityAdditionalInsurance(
              formVal.commercial_general_liability_additional_insurance,
              franchisorData.commercial_general_liability_additional_insurance,
              franchisorData.commercial_general_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="checkbox-container">
            <Checkbox
              checked={formVal.commercial_general_liability_subrogration_waved}
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_subrogration_waved",
                  evt.target.checked
                )
              }
            >
              Waiver Of Subrogation
            </Checkbox>
            {buildGeneralLiabilitySubrogrationWaved(
              formVal.commercial_general_liability_subrogration_waved,
              franchisorData.commercial_general_liability_subrogration_waved,
              franchisorData.commercial_general_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>

          <div className="alert-container">
            <h4>
              POLICY NUMBER:{" "}
              {formVal.commercial_general_liability_policy_number}
            </h4>
            <h4>
              EFFECTIVE DATE:{" "}
              {formVal.commercial_general_liability_effective_date}
            </h4>
            {buildEffectDate(
              formVal.commercial_general_liability_effective_date,
              franchisorData.commercial_general_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
            <h4>
              EXPIRATION DATE:{" "}
              {formVal.commercial_general_liability_expire_date}
            </h4>
            {buildExpireDate(
              formVal.commercial_general_liability_expire_date,
              franchisorData.commercial_general_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
        <div className="panel-right">
          <div className="line-item-container">
            <Input
              title="EACH OCCURRENCE"
              placeholder="EACH OCCURRENCE"
              value={formVal.commercial_general_liability_each_occurance}
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_each_occurance",
                  evt.target.value
                )
              }
            />
            {buildValueGT(
              formVal.commercial_general_liability_each_occurance,
              franchisorData.commercial_general_liability_each_occurance,
              franchisorData.commercial_general_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="line-item-container">
            <Input
              title="DAMAGE TO RENTED PERMISES"
              placeholder="DAMAGE TO RENTED PERMISES"
              value={
                formVal.commercial_general_liability_damage_rented_premises
              }
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_damage_rented_premises",
                  evt.target.value
                )
              }
            />
            {buildValueGT(
              formVal.commercial_general_liability_damage_rented_premises,
              franchisorData.commercial_general_liability_damage_rented_premises,
              franchisorData.commercial_general_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="line-item-container">
            <Input
              title="MED EXP"
              subTitle="( Any one person )"
              placeholder="MED EXP"
              value={formVal.commercial_general_liability_medical_expenses}
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_medical_expenses",
                  evt.target.value
                )
              }
            />
            {buildValueGT(
              formVal.commercial_general_liability_medical_expenses,
              franchisorData.commercial_general_liability_medical_expenses,
              franchisorData.commercial_general_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="line-item-container">
            <Input
              title="PERSONAL & ADV INJURY"
              placeholder="PERSONAL & ADV INJURY"
              value={formVal.commercial_general_liability_personal_adv_injury}
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_personal_adv_injury",
                  evt.target.value
                )
              }
            />
            {buildValueGT(
              formVal.commercial_general_liability_personal_adv_injury,
              franchisorData.commercial_general_liability_personal_adv_injury,
              franchisorData.commercial_general_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="line-item-container">
            <Input
              title="GENERAL AGGREGATE"
              placeholder="GENERAL AGGREGATE"
              value={formVal.commercial_general_liability_general_aggregate}
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_general_aggregate",
                  evt.target.value
                )
              }
            />
            {buildValueGT(
              formVal.commercial_general_liability_general_aggregate,
              franchisorData.commercial_general_liability_general_aggregate,
              franchisorData.commercial_general_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="line-item-container">
            <Input
              title="PRODUCTS - COMP / OP AGG"
              placeholder="PRODUCTS - COMP / OP AGG"
              value={formVal.commercial_general_liability_comprop_acg}
              onChange={(evt) =>
                handleChange(
                  "commercial_general_liability_comprop_acg",
                  evt.target.value
                )
              }
            />
            {buildValueGT(
              formVal.commercial_general_liability_comprop_acg,
              franchisorData.commercial_general_liability_comprop_acg,
              franchisorData.commercial_general_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
      </div>
      <div className="report-liability-content-mask"></div>
    </div>
  );
}
