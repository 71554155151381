import React from "react";
import { Input } from "@components";
import { Select, DatePicker } from "antd";
import { ENTITY_CODE } from "@assets/const/legal-entity";
import { APP_DATE_FORMAT } from "@assets/const/form";
import { isEmpty } from "lodash";
import { TYPE_INPUT_TAX_ID } from "@assets/const/ui";
import dayjs from "dayjs";
import "./style.scss";

const { Option } = Select;

export default function BlockEntity({ data, onChange }) {
  function handleChange(key, value) {
    onChange((prev) => ({ ...prev, [key]: value }));
  }

  return (
    <div className="block_entity">
      <div className="form-group-four-container">
        <Input
          title="Entity Name"
          type="text"
          required={true}
          value={data.entity_name}
          onChange={(evt) => handleChange("entity_name", evt.target.value)}
        />
        <div>
          <p className="input-title">
            Entity Type<span className="input-required">*</span>
          </p>
          <Select
            size="large"
            style={{ minWidth: "120px", width: "100%" }}
            value={data.entity_type}
            onChange={(value) => handleChange("entity_type", value)}
          >
            {ENTITY_CODE.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </div>
        <Input
          title="DBA"
          type="text"
          required
          value={data.dba}
          onChange={(evt) => handleChange("dba", evt.target.value)}
        />
        <div>
          <p className="input-title">Opening Date</p>
          <DatePicker
            size="large"
            format={APP_DATE_FORMAT}
            value={
              !isEmpty(data.opening_date) ? dayjs(data.opening_date) : null
            }
            style={{ width: "100%" }}
            onChange={(_, dateString) =>
              handleChange("opening_date", dateString)
            }
          ></DatePicker>
        </div>
        <Input
          title="Tax ID"
          type="text"
          inputType={TYPE_INPUT_TAX_ID}
          value={data.tax_id}
          onChange={(evt) => handleChange("tax_id", evt.target.value)}
        />
        <Input
          title="Center Name/Location Number"
          type="text"
          value={data.centerName}
          onChange={(evt) => handleChange("centerName", evt.target.value)}
        />
        <Input
          title="Estimated Annual Revenue"
          type="text"
          value={data.annualRevenue}
          onChange={(value) => handleChange("annualRevenue", value)}
          size="large"
          inputType="TYPE_INPUT_CURRENCY"
          style={{ width: "100%" }}
          formatter={(value) =>
            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
        />
      </div>
    </div>
  );
}
