import React, { useEffect, useState, useMemo } from "react";
import "./style.scss";
import { Breadcrumb, notification } from "antd";
import { useHistory, useLocation, useParams } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { get, put, post } from "@utils/axios";
import CollapseWrapper from "./collapse-wrapper";
import LiabilityForm from "./liability-form";
import AutoForm from "./auto-form";
import WorkersCompForm from "./workers-comp-form";
import UmbrellaForm from "./umbrella-form";
import CrimeForm from "./crime-form";
import GarageForm from "./garage-form";
import AdditionalReport from "./additional-report";
import AdditionalInfoForm from "./additional-info-form";
import { get as getData } from "lodash";
import { Loading } from "@components";
import EpliForm from "./epli-form";
import CyberForm from "./cyber-form";
import ProLiabForm from "./pro-liab-form";
import PolLiabForm from "./pol-liab-form";
import InlandMarineForm from "./im-form";
import ProInsForm from "./pro-ins-form";
import Card from "@components/card";
import LocationForm from "./location-form";

export default function FranchiseeSummary(props) {
  const history = useHistory();
  const location = useLocation();
  let { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [franchiseeData, setFranchiseeData] = useState({});
  const [franchisorData, setFranchisorData] = useState({});
  const [loading, setLoading] = useState(false);
  const [docIndex, setDocIndex] = useState({
    commercial_general_liability_form_doc_index: -1,
    automotive_liability_form_doc_index: -1,
    workers_compensation_form_doc_index: -1,
    umbrella_subrogration_form_doc_index: -1,
    additional_infor_doc_index: -1,
    crime_form_doc_index: -1,
    garage_form_doc_index: -1,
    epli_form_doc_index: -1,
    cyber_form_doc_index: -1,
    pro_liab_form_doc_index: -1,
    pol_liab_form_doc_index: -1,
    im_form_doc_index: -1,
    pro_ins_form_doc_index: -1,
  });

  useEffect(() => {
    if (id) {
      handleLoadFranchisee();
    }
  }, []);

  async function handleLoadFranchisee() {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const result = await get(`contact/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFranchiseeData(result.data.data);
      setDocIndex({
        commercial_general_liability_form_doc_index:
          result?.data?.data?.finalData
            ?.commercial_general_liability_form_doc_index,
        automotive_liability_form_doc_index:
          result?.data?.data?.finalData?.automotive_liability_form_doc_index,
        workers_compensation_form_doc_index:
          result?.data?.data?.finalData?.workers_compensation_form_doc_index,
        umbrella_subrogration_form_doc_index:
          result?.data?.data?.finalData?.umbrella_subrogration_form_doc_index,
        additional_infor_doc_index:
          result?.data?.data?.finalData?.additional_infor_doc_index,
        crime_form_doc_index:
          result?.data?.data?.finalData?.crime_form_doc_index,
        garage_form_doc_index:
          result?.data?.data?.finalData?.garage_form_doc_index,
        epli_form_doc_index: result?.data?.data?.finalData?.epli_form_doc_index,
        cyber_form_doc_index:
          result?.data?.data?.finalData?.cyber_form_doc_index,
        pro_liab_form_doc_index:
          result?.data?.data?.finalData?.pro_liab_form_doc_index,
        pol_liab_form_doc_index:
          result?.data?.data?.finalData?.pol_liab_form_doc_index,
        im_form_doc_index: result?.data?.data?.finalData?.im_form_doc_index,
        pro_ins_form_doc_index:
          result?.data?.data?.finalData?.pro_ins_form_doc_index,
      });
      const resultFranchisorData = await get(
        `requirement/${result.data.data.requirementId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const rawFranchisorData = getData(resultFranchisorData, "data.data");
      if (rawFranchisorData) {
        setFranchisorData(rawFranchisorData);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      handleNotification(
        "Failed",
        "Franchisee Data loading is failed. Please contact to admin directly"
      );
    }
  }

  function handleNotification(title, description) {
    notification.open({
      message: title,
      description: description,
      duration: 3,
    });
  }

  function handleBackFranchisor() {
    history.push("/admin/franchisees");
  }

  function handleBackFranchiseeDetail() {
    const pathname = location.pathname.split("/summary");
    history.push(pathname[0]);
  }

  function handleChangeDocIndex(key, newIndex) {
    setDocIndex({
      ...docIndex,
      [key]: newIndex,
    });
  }

  function handleUpdateForm(updatedData) {
    setFranchiseeData({
      ...franchiseeData,
      ...updatedData,
    });
  }

  const franchiseeName = useMemo(() => {
    if (franchiseeData) {
      if (franchiseeData?.isInsureDiff) {
        return `${franchiseeData?.insuranceData?.first_name || "Franchisee"} ${
          franchiseeData?.insuranceData?.last_name || "Name"
        }`;
      }

      return `${franchiseeData.first_name || "Franchisee"} ${
        franchiseeData.last_name || "Name"
      }`;
    }

    return "Franchisee Name";
  }, [franchiseeData]);

  return (
    <div className="franchisee-summary-container">
      <div className="header-wrapper">
        <Breadcrumb>
          <Breadcrumb.Item onClick={handleBackFranchisor}>
            {franchisorData?.personal_infor_business_name || "Franchisor"}
          </Breadcrumb.Item>
          <Breadcrumb.Item onClick={handleBackFranchiseeDetail}>
            {franchiseeName}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{`Summary`}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="content-container">
        <div className="card-container">
          <div className="card-content">
            <AdditionalReport
              docIndex={docIndex}
              franchiseeData={franchiseeData}
              franchisorData={franchisorData}
            />
          </div>
        </div>
        <div className="card-container">
          <div className="card-content">
            <LocationForm
              locationList={franchiseeData?.locationList}
              franchiseeData={franchiseeData}
              docIndex={docIndex.commercial_general_liability_form_doc_index}
            />
          </div>
        </div>

        {franchisorData?.commercial_general_liability_required && (
          <div className="card-container">
            <div className="card-content">
              <CollapseWrapper
                title="Liability"
                recogData={franchiseeData?.recogData}
                fileUpload={franchiseeData?.fileUpload}
                docItemIndex={
                  docIndex.commercial_general_liability_form_doc_index
                }
                updateIndex={(newIndex) =>
                  handleChangeDocIndex(
                    "commercial_general_liability_form_doc_index",
                    newIndex
                  )
                }
                contactKey="contact_liability"
                contactFlag={franchiseeData?.contact_liability}
                franchiseeID={franchiseeData?._id}
                firstName={
                  franchiseeData?.insuranceData?.first_name ||
                  franchiseeData?.first_name ||
                  ""
                }
                lastName={
                  franchiseeData?.insuranceData?.last_name ||
                  franchiseeData?.last_name ||
                  ""
                }
              >
                <LiabilityForm
                  franchiseeData={franchiseeData}
                  franchisorData={franchisorData}
                  docIndex={
                    docIndex.commercial_general_liability_form_doc_index
                  }
                  handleUpdateForm={handleUpdateForm}
                />
              </CollapseWrapper>
            </div>
          </div>
        )}

        {franchisorData?.automotive_liability_required && (
          <div className="card-container">
            <div className="card-content">
              <CollapseWrapper
                title="Auto"
                recogData={franchiseeData?.recogData}
                fileUpload={franchiseeData?.fileUpload}
                docItemIndex={docIndex.automotive_liability_form_doc_index}
                updateIndex={(newIndex) =>
                  handleChangeDocIndex(
                    "automotive_liability_form_doc_index",
                    newIndex
                  )
                }
                contactKey="contact_auto"
                contactFlag={franchiseeData?.contact_auto}
                franchiseeID={franchiseeData?._id}
                firstName={
                  franchiseeData?.insuranceData?.first_name ||
                  franchiseeData?.first_name ||
                  ""
                }
                lastName={
                  franchiseeData?.insuranceData?.last_name ||
                  franchiseeData?.last_name ||
                  ""
                }
              >
                <AutoForm
                  franchiseeData={franchiseeData}
                  franchisorData={franchisorData}
                  docIndex={docIndex.automotive_liability_form_doc_index}
                  handleUpdateForm={handleUpdateForm}
                />
              </CollapseWrapper>
            </div>
          </div>
        )}
        {franchisorData?.workers_compensation_required && (
          <div className="card-container">
            <div className="card-content">
              <CollapseWrapper
                title="Workers Compensation"
                recogData={franchiseeData?.recogData}
                fileUpload={franchiseeData?.fileUpload}
                docItemIndex={docIndex.workers_compensation_form_doc_index}
                updateIndex={(newIndex) =>
                  handleChangeDocIndex(
                    "workers_compensation_form_doc_index",
                    newIndex
                  )
                }
                contactKey="contact_work_comp"
                contactFlag={franchiseeData?.contact_work_comp}
                franchiseeID={franchiseeData?._id}
                firstName={
                  franchiseeData?.insuranceData?.first_name ||
                  franchiseeData?.first_name ||
                  ""
                }
                lastName={
                  franchiseeData?.insuranceData?.last_name ||
                  franchiseeData?.last_name ||
                  ""
                }
              >
                <WorkersCompForm
                  franchiseeData={franchiseeData}
                  franchisorData={franchisorData}
                  docIndex={docIndex.workers_compensation_form_doc_index}
                  handleUpdateForm={handleUpdateForm}
                />
              </CollapseWrapper>
            </div>
          </div>
        )}
        {franchisorData?.umbrella_subrogration_required && (
          <div className="card-container">
            <div className="card-content">
              <CollapseWrapper
                title="Umbrella"
                recogData={franchiseeData?.recogData}
                fileUpload={franchiseeData?.fileUpload}
                docItemIndex={docIndex.umbrella_subrogration_form_doc_index}
                updateIndex={(newIndex) =>
                  handleChangeDocIndex(
                    "umbrella_subrogration_form_doc_index",
                    newIndex
                  )
                }
                contactKey="contact_umbrella"
                contactFlag={franchiseeData?.contact_umbrella}
                franchiseeID={franchiseeData?._id}
                firstName={
                  franchiseeData?.insuranceData?.first_name ||
                  franchiseeData?.first_name ||
                  ""
                }
                lastName={
                  franchiseeData?.insuranceData?.last_name ||
                  franchiseeData?.last_name ||
                  ""
                }
              >
                <UmbrellaForm
                  franchiseeData={franchiseeData}
                  franchisorData={franchisorData}
                  docIndex={docIndex.umbrella_subrogration_form_doc_index}
                  handleUpdateForm={handleUpdateForm}
                />
              </CollapseWrapper>
            </div>
          </div>
        )}

        {franchisorData?.crime_required && (
          <div className="card-container">
            <div className="card-content">
              <CollapseWrapper
                title="Crime"
                recogData={franchiseeData?.recogData}
                fileUpload={franchiseeData?.fileUpload}
                docItemIndex={docIndex.crime_form_doc_index}
                updateIndex={(newIndex) =>
                  handleChangeDocIndex("crime_form_doc_index", newIndex)
                }
                contactKey="contact_crime"
                contactFlag={franchiseeData?.contact_crime}
                franchiseeID={franchiseeData?._id}
                firstName={
                  franchiseeData?.insuranceData?.first_name ||
                  franchiseeData?.first_name ||
                  ""
                }
                lastName={
                  franchiseeData?.insuranceData?.last_name ||
                  franchiseeData?.last_name ||
                  ""
                }
              >
                <CrimeForm
                  franchiseeData={franchiseeData}
                  franchisorData={franchisorData}
                  docIndex={docIndex.crime_form_doc_index}
                  handleUpdateForm={handleUpdateForm}
                />
              </CollapseWrapper>
            </div>
          </div>
        )}

        {franchisorData?.garage_required && (
          <div className="card-container">
            <div className="card-content">
              <CollapseWrapper
                title="Garage"
                recogData={franchiseeData?.recogData}
                fileUpload={franchiseeData?.fileUpload}
                docItemIndex={docIndex.garage_form_doc_index}
                updateIndex={(newIndex) =>
                  handleChangeDocIndex("garage_form_doc_index", newIndex)
                }
                contactKey="contact_garage"
                contactFlag={franchiseeData?.contact_garage}
                franchiseeID={franchiseeData?._id}
                firstName={
                  franchiseeData?.insuranceData?.first_name ||
                  franchiseeData?.first_name ||
                  ""
                }
                lastName={
                  franchiseeData?.insuranceData?.last_name ||
                  franchiseeData?.last_name ||
                  ""
                }
              >
                <GarageForm
                  franchiseeData={franchiseeData}
                  franchisorData={franchisorData}
                  docIndex={docIndex.garage_form_doc_index}
                  handleUpdateForm={handleUpdateForm}
                />
              </CollapseWrapper>
            </div>
          </div>
        )}

        {franchisorData?.epli_required && (
          <div className="card-container">
            <div className="card-content">
              <CollapseWrapper
                title="Employment Practices Liability"
                recogData={franchiseeData?.recogData}
                fileUpload={franchiseeData?.fileUpload}
                docItemIndex={docIndex.epli_form_doc_index}
                updateIndex={(newIndex) =>
                  handleChangeDocIndex("epli_form_doc_index", newIndex)
                }
                contactKey="contact_epli"
                contactFlag={franchiseeData?.contact_epli}
                franchiseeID={franchiseeData?._id}
                firstName={
                  franchiseeData?.insuranceData?.first_name ||
                  franchiseeData?.first_name ||
                  ""
                }
                lastName={
                  franchiseeData?.insuranceData?.last_name ||
                  franchiseeData?.last_name ||
                  ""
                }
              >
                <EpliForm
                  franchiseeData={franchiseeData}
                  franchisorData={franchisorData}
                  docIndex={docIndex.epli_form_doc_index}
                  handleUpdateForm={handleUpdateForm}
                />
              </CollapseWrapper>
            </div>
          </div>
        )}

        {franchisorData?.cyber_required && (
          <div className="card-container">
            <div className="card-content">
              <CollapseWrapper
                title="Cyber Liability"
                recogData={franchiseeData?.recogData}
                fileUpload={franchiseeData?.fileUpload}
                docItemIndex={docIndex.cyber_form_doc_index}
                updateIndex={(newIndex) =>
                  handleChangeDocIndex("cyber_form_doc_index", newIndex)
                }
                contactKey="contact_cyber"
                contactFlag={franchiseeData?.contact_cyber}
                franchiseeID={franchiseeData?._id}
                firstName={
                  franchiseeData?.insuranceData?.first_name ||
                  franchiseeData?.first_name ||
                  ""
                }
                lastName={
                  franchiseeData?.insuranceData?.last_name ||
                  franchiseeData?.last_name ||
                  ""
                }
              >
                <CyberForm
                  franchiseeData={franchiseeData}
                  franchisorData={franchisorData}
                  docIndex={docIndex.cyber_form_doc_index}
                  handleUpdateForm={handleUpdateForm}
                />
              </CollapseWrapper>
            </div>
          </div>
        )}

        {franchisorData?.pro_liab_required && (
          <div className="card-container">
            <div className="card-content">
              <CollapseWrapper
                title="Professional Liability"
                recogData={franchiseeData?.recogData}
                fileUpload={franchiseeData?.fileUpload}
                docItemIndex={docIndex.pro_liab_form_doc_index}
                updateIndex={(newIndex) =>
                  handleChangeDocIndex("pro_liab_form_doc_index", newIndex)
                }
                contactKey="contact_pro_liab"
                contactFlag={franchiseeData?.contact_pro_liab}
                franchiseeID={franchiseeData?._id}
                firstName={
                  franchiseeData?.insuranceData?.first_name ||
                  franchiseeData?.first_name ||
                  ""
                }
                lastName={
                  franchiseeData?.insuranceData?.last_name ||
                  franchiseeData?.last_name ||
                  ""
                }
              >
                <ProLiabForm
                  franchiseeData={franchiseeData}
                  franchisorData={franchisorData}
                  docIndex={docIndex.pro_liab_form_doc_index}
                  handleUpdateForm={handleUpdateForm}
                />
              </CollapseWrapper>
            </div>
          </div>
        )}

        {franchisorData?.pol_liab_required && (
          <div className="card-container">
            <div className="card-content">
              <CollapseWrapper
                title="Pollution Liability"
                recogData={franchiseeData?.recogData}
                fileUpload={franchiseeData?.fileUpload}
                docItemIndex={docIndex.pol_liab_form_doc_index}
                updateIndex={(newIndex) =>
                  handleChangeDocIndex("pol_liab_form_doc_index", newIndex)
                }
                contactKey="contact_pol_liab"
                contactFlag={franchiseeData?.contact_pol_liab}
                franchiseeID={franchiseeData?._id}
                firstName={
                  franchiseeData?.insuranceData?.first_name ||
                  franchiseeData?.first_name ||
                  ""
                }
                lastName={
                  franchiseeData?.insuranceData?.last_name ||
                  franchiseeData?.last_name ||
                  ""
                }
              >
                <PolLiabForm
                  franchiseeData={franchiseeData}
                  franchisorData={franchisorData}
                  docIndex={docIndex.pol_liab_form_doc_index}
                  handleUpdateForm={handleUpdateForm}
                />
              </CollapseWrapper>
            </div>
          </div>
        )}

        {franchisorData?.im_required && (
          <div className="card-container">
            <div className="card-content">
              <CollapseWrapper
                title="Inland Marine"
                recogData={franchiseeData?.recogData}
                fileUpload={franchiseeData?.fileUpload}
                docItemIndex={docIndex.im_form_doc_index}
                updateIndex={(newIndex) =>
                  handleChangeDocIndex("im_form_doc_index", newIndex)
                }
                contactKey="contact_im"
                contactFlag={franchiseeData?.contact_im}
                franchiseeID={franchiseeData?._id}
                firstName={
                  franchiseeData?.insuranceData?.first_name ||
                  franchiseeData?.first_name ||
                  ""
                }
                lastName={
                  franchiseeData?.insuranceData?.last_name ||
                  franchiseeData?.last_name ||
                  ""
                }
              >
                <InlandMarineForm
                  franchiseeData={franchiseeData}
                  franchisorData={franchisorData}
                  docIndex={docIndex.im_form_doc_index}
                  handleUpdateForm={handleUpdateForm}
                />
              </CollapseWrapper>
            </div>
          </div>
        )}

        {franchisorData?.pro_ins_required && (
          <div className="card-container">
            <div className="card-content">
              <CollapseWrapper
                title="Property Insurance"
                recogData={franchiseeData?.recogData}
                fileUpload={franchiseeData?.fileUpload}
                docItemIndex={docIndex.pro_ins_form_doc_index}
                updateIndex={(newIndex) =>
                  handleChangeDocIndex("pro_ins_form_doc_index", newIndex)
                }
                contactKey="contact_pro_ins"
                contactFlag={franchiseeData?.contact_pro_ins}
                franchiseeID={franchiseeData?._id}
                firstName={
                  franchiseeData?.insuranceData?.first_name ||
                  franchiseeData?.first_name ||
                  ""
                }
                lastName={
                  franchiseeData?.insuranceData?.last_name ||
                  franchiseeData?.last_name ||
                  ""
                }
              >
                <ProInsForm
                  franchiseeData={franchiseeData}
                  franchisorData={franchisorData}
                  docIndex={docIndex.pro_ins_form_doc_index}
                  handleUpdateForm={handleUpdateForm}
                />
              </CollapseWrapper>
            </div>
          </div>
        )}

        <div className="card-container">
          <div className="card-content">
            <CollapseWrapper
              title="Certificate Holder Information"
              recogData={franchiseeData?.recogData}
              fileUpload={franchiseeData?.fileUpload}
              docItemIndex={docIndex.additional_infor_doc_index}
              updateIndex={(newIndex) =>
                handleChangeDocIndex("additional_infor_doc_index", newIndex)
              }
              contactKey="contact_additional_information"
              contactFlag={franchiseeData?.contact_additional_information}
              franchiseeID={franchiseeData?._id}
              firstName={
                franchiseeData?.insuranceData?.first_name ||
                franchiseeData?.first_name ||
                ""
              }
              lastName={
                franchiseeData?.insuranceData?.last_name ||
                franchiseeData?.last_name ||
                ""
              }
            >
              <AdditionalInfoForm
                franchiseeData={franchiseeData}
                franchisorData={franchisorData}
                docIndex={docIndex.additional_infor_doc_index}
                handleUpdateForm={handleUpdateForm}
              />
            </CollapseWrapper>
          </div>
        </div>
        <Loading loading={loading} />
      </div>
    </div>
  );
}
