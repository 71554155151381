import React, { useState, useEffect } from "react";
import { Input } from "../../../../components";
import { Radio } from "antd";
import "./style.scss";
import {
  buildEffectDate,
  buildExpireDate,
  buildValueGT,
  buildPollutionType,
} from "../../../../utils/build-logic";
import { FORM_CLAIMS_MADE, FORM_OCCURRENCE } from "@assets/const/form";
import { STATUS_SECTION_APPROVE, DOC_MAX_LIMIT } from "@assets/const/status";
import { mapFieldPolLiab } from "@utils/form-recog";

export default function PolLiabForm(props) {
  const { docIndex, franchisorData } = props;

  const [formVal, setFormVal] = useState(handleGetFormVal());

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    if (
      franchiseeData?.finalData?.pol_liab_form_status === STATUS_SECTION_APPROVE
    ) {
      const finalData = franchiseeData.finalData;

      return {
        pol_liab_occ: finalData.pol_liab_occ,
        pol_liab_agg: finalData.pol_liab_agg,
        pol_liab_type: finalData.pol_liab_type,
        pol_liab_policy_number: finalData.pol_liab_policy_number,
        pol_liab_effective_date: finalData.pol_liab_effective_date,
        pol_liab_expire_date: finalData.pol_liab_expire_date,
      };
    }

    if (docIndex >= 0 && docIndex !== DOC_MAX_LIMIT) {
      const data = franchiseeData?.recogData[docIndex][0]?.fields;
      if (data) {
        return mapFieldPolLiab(data);
      }
    }

    return {};
  }

  const [loading, setLoading] = useState(false);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  return (
    <div className="report-pol-liab-content">
      <div className="report-pol-liab-content-analysis">
        <label>
          <b>Analysis:</b>
        </label>

        {buildPollutionType(
          formVal.pol_liab_type,
          franchisorData.pol_liab_type,
          franchisorData.pol_liab_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}

        {buildEffectDate(
          formVal.pol_liab_effective_date,
          franchisorData?.pol_liab_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
        {buildExpireDate(
          formVal.pol_liab_expire_date,
          franchisorData.pol_liab_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
        {buildValueGT(
          formVal.pol_liab_occ,
          franchisorData.pol_liab_occ,
          franchisorData?.pol_liab_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
        {buildValueGT(
          formVal.pol_liab_agg,
          franchisorData.pol_liab_agg,
          franchisorData.pol_liab_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
      </div>

      <div className="form-main">
        <div className="panel-left">
          <div className="radio-container">
            <h5 className="input-title">POLLUTION TYPE</h5>
            <Radio.Group
              onChange={(evt) =>
                handleChange("pol_liab_type", evt.target.value)
              }
              value={formVal.pol_liab_type}
            >
              <Radio value={FORM_OCCURRENCE}>Occurrence</Radio>
              <Radio value={FORM_CLAIMS_MADE}>Claims Made</Radio>
            </Radio.Group>
            {buildPollutionType(
              formVal.pol_liab_type,
              franchisorData.pol_liab_type,
              franchisorData.pol_liab_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="alert-container">
            <h4>POLICY NUMBER: {formVal.pol_liab_policy_number}</h4>
            <h4>EFFECTIVE DATE: {formVal.pol_liab_effective_date}</h4>
            {buildEffectDate(
              formVal.pol_liab_effective_date,
              franchisorData.pol_liab_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
            <h4>EXPIRATION DATE: {formVal.pol_liab_expire_date}</h4>
            {buildExpireDate(
              formVal.pol_liab_expire_date,
              franchisorData.pol_liab_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
        <div className="panel-right">
          <div className="line-item-container">
            <Input
              title="EACH OCCURRENCE"
              placeholder="EACH OCCURRENCE"
              value={formVal.pol_liab_occ}
              onChange={(evt) => handleChange("pol_liab_occ", evt.target.value)}
            />
            {buildValueGT(
              formVal.pol_liab_occ,
              franchisorData.pol_liab_occ,
              franchisorData?.pol_liab_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="line-item-container">
            <Input
              title="AGGREGATE"
              placeholder="AGGREGATE"
              value={formVal.pol_liab_agg}
              onChange={(evt) => handleChange("pol_liab_agg", evt.target.value)}
            />

            {buildValueGT(
              formVal.pol_liab_agg,
              franchisorData.pol_liab_agg,
              franchisorData.pol_liab_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
      </div>
      <div className="report-pol-liab-content-mask"></div>
    </div>
  );
}
