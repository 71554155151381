import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CheckOutlined,
  StopOutlined,
  CalendarOutlined
} from "@ant-design/icons";
import { Table } from "antd";
import { Loading, OriginalRecordForm } from "../../../components";
import { useParams, useHistory } from "react-router-dom";
import "./style.scss";
import { post, get, put, postDoc } from "@utils/axios";
import iconRikorLogo from "../../../assets/images/rikor-logo.svg";
import LocationBlock from "../../admin-board/franchisee-detail/under-writing/location-block";
import _ from "lodash";
import UploadForm from "./upload-form";
import moment from "moment";

const KEY_SAVE_RECORD = "KEY_SAVE_RECORD";
const KEY_SAVE_LOCATION = "KEY_SAVE_LOCATION";

export default function FranchiseeUpload() {
  const param = useParams();

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [entityData, setEntityData] = useState({});
  const [locationList, setLocationList] = useState([]);
  const [calendarLink, setCalendarLink] = useState(null);
  const [compliance, setCompliance] = useState([]);
  const [franchisorLogoUri, setFranchisorLogoUri] = useState("");
  const [location, setLocation] = useState(0);

  useEffect(() => {
    handleLoadContact();
  }, []);

  async function handleLoadContact() {
    setLoading(true);
    try {
      const result = await get(`contact/${param.id}/entity`);
      setFranchisorLogoUri(result?.data?.franchisorLogoUri || "");
      setEntityData(result?.data?.recordData);
      setLocationList(result?.data?.locationList);
      setCalendarLink(result?.data?.link);
      setCompliance(result?.data?.compliance);
    } catch (error) {
      console.log("load contact error", error);
    }
    setLoading(false);
  }

  async function handleAutoSaveRecord(key, data) {
    try {
      const result = await put(`contact/${param.id}/entity`, {
        key: key,
        data
      });
    } catch (error) {
      console.log("load contact error", error);
    }
  }

  const locationOptions = useMemo(() => {
    if (locationList) {
      return locationList.map((item, index) => {
        return {
          value: index,
          label: `${item.address} ${item.city} ${item.state}`
        };
      });
    }

    return [];
  }, [locationList]);

  async function handleSaveRecord(key) {
    setLoading(true);
    try {
      let data = key === KEY_SAVE_LOCATION ? locationList : entityData;
      const result = await put(`contact/${param.id}/entity`, {
        key: key,
        data
      });
    } catch (error) {
      console.log("load contact error", error);
    }
    setLoading(false);
  }

  const columnsCompliance = [
    {
      title: "Required",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Compliance Status",
      dataIndex: "compliant",
      key: "compliant"
    },
    {
      title: "Expiration Date",
      dataIndex: "expirationDate",
      key: "expirationDate",
      render: (expirationDate) => (
        <span>
          {expirationDate ? moment(expirationDate).format("MM/DD/YYYY") : ""}
        </span>
      )
    },
    {
      title: "Issues",
      dataIndex: "analysis",
      key: "analysis"
    }
  ];

  function handleViewReport() {
    history.push(`/coiportal/report/${param.id}`);
  }

  return (
    <div className="franchisee-upload">
      <div className="franchisee-upload-container">
        <div className="franchisee-upload-container-header">
          <img
            src={iconRikorLogo}
            className="franchisee-upload-container-header-img"
          />
          {franchisorLogoUri && <img src={franchisorLogoUri} width={250} />}
          {/* <a
            href={calendarLink}
            target="_blank"
            className={
              calendarLink
                ? "franchisee-upload-container-header-btn"
                : "franchisee-upload-container-header-btn disable-btn"
            }
          >
            <CalendarOutlined style={{ marginRight: "10px" }} />
            Schedule an appointment for a quote
          </a> */}
        </div>
        <div className="card card-section">
          <div className="card-header">
            <div className="card-header-wrapper">
              <h3>Need to upload your COI?</h3>
              {/* <Select
                style={{ width: "200px" }}
                size="large"
                value={location}
                options={locationOptions}
                onChange={(value) => {
                  setLocation(value);
                }}
              ></Select> */}
            </div>
          </div>
          <div className="card-content">
            <div className="section-upload">
              <p className="section-upload-title">
                {entityData?.entity_name || ""}, upload your COI here...
              </p>
            </div>
            <span>Make sure your COI lists the following locations:</span>
            {locationList.map((locationItem, index) => (
              <div className="upload_form-title">
                {locationItem?.address}, {locationItem?.city},{" "}
                {locationItem?.state}
              </div>
            ))}
            <UploadForm franchiseeId={param.id} setLoading={setLoading} />
          </div>
          <div className="card-footer"></div>
        </div>
        <div className="card card-section">
          <div className="card-header">
            <div className="card-header-wrapper">
              <h3>FDD Required Coverages</h3>
            </div>
          </div>
          <div className="card-content">
            <Table dataSource={compliance} columns={columnsCompliance} />
          </div>
        </div>
        <div className="card card-section">
          <div className="card-header">
            <div className="card-header-wrapper">
              <h3>View Detailed Insurance Report</h3>
              {/* <Button
                onClick={() => handleSaveRecord(KEY_SAVE_LOCATION)}
                type="secondary"
              >
                Save
              </Button> */}
            </div>
          </div>
          <div className="card-content">
            <a
              className="franchisee-upload-container-header-btn"
              onClick={handleViewReport}
            >
              View Report
            </a>
          </div>
        </div>
      </div>
      <Loading loading={loading} />
    </div>
  );
}
