import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { put, post } from "@utils/axios";
import { Modal, Form, notification, Button, Alert, Space } from "antd";
import { getPrimaryContact } from "@utils/common";
import { USER_TYPE_FRANCHISEE } from "@assets/const/status";
import { getHostTag } from "@utils/host";
import { ERROR_TYPE_CONTINUE, ERROR_TYPE_QUIT } from "@assets/const/form";
import { LinkOutlined } from "@ant-design/icons";
import ZorPOCForm from "@components/zor-poc";

export default function POCCreate({
  isOpen,
  onClose,
  franchiseeData,
  setFranchiseeData
}) {
  const [zeeForm] = Form.useForm();
  const { getAccessTokenSilently, user } = useAuth0();

  const [formValues, setFormValues] = useState();
  const [loadingModal, setLoadingModal] = useState(false);
  const [error, setError] = useState();

  function handleGetFormVal() {
    const pocContacts = JSON.parse(
      JSON.stringify(franchiseeData?.poc_contacts || [])
    );
    const primaryContact = getPrimaryContact(franchiseeData);
    if (
      primaryContact?.email &&
      !pocContacts.find(
        (contactItem) => contactItem.email === primaryContact.email
      )
    ) {
      pocContacts.unshift(primaryContact);
    }
    return pocContacts;
  }

  function handleNotification(title, description) {
    notification["error"]({
      message: title,
      description: description,
      duration: 3
    });
  }

  async function handleOverWrite() {
    setLoadingModal(true);
    try {
      const contactList = handleGetFormVal();
      const logPOCContacts = franchiseeData?.log_poc_contacts || [];
      const token = await getAccessTokenSilently();
      await put(
        `user/${formValues._id}`,
        {
          contactId: franchiseeData._id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      contactList.push({
        firstName: formValues.first_name,
        lastName: formValues.last_name,
        email: formValues.email,
        telephone: formValues.telephone,
        directLine: formValues.directLine,
        preferredMethod: formValues.preferredMethod,
        role: formValues.role,
        isPrimary: formValues.isPrimary,
        isActive: formValues.isActive
      });

      logPOCContacts.push({
        updatedBy: user.email,
        updatedAt: new Date(),
        data: contactList
      });

      const result = await put(
        `contact/${franchiseeData._id}`,
        {
          poc_contacts: contactList,
          log_poc_contacts: logPOCContacts
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFranchiseeData(result.data.entity);
      //   setContactList([...contactList]);
      //   setLogPOCContacts([...logPOCContacts]);
      zeeForm.resetFields();
      onClose();
      setError(null);
      setFormValues(null);
    } catch (error) {}
    setLoadingModal(false);
  }

  async function handleAddPOC(values) {
    setLoadingModal(true);
    setError(null);
    const token = await getAccessTokenSilently();

    try {
      // "regUser"
      const result = await post(
        `admin/user/query`,
        { email: values.email, requiredType: USER_TYPE_FRANCHISEE },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (result?.data?.errorStatus) {
        setError({
          type: result?.data?.errorStatus,
          msg: result?.data?.msg
        });
        setFormValues({ ...result?.data?.data, telephone: values.telephone });
        setLoadingModal(false);
        return;
      }
    } catch (error) {}

    await handleCreateContact(values);
    setLoadingModal(false);
  }

  async function handleCreateContact(values) {
    setLoadingModal(true);
    const contactList = handleGetFormVal();
    const logPOCContacts = franchiseeData?.log_poc_contacts || [];
    const token = await getAccessTokenSilently();
    try {
      await post(
        `admin/user/create`,
        {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          password: values.password,
          name: `${values.firstName} ${values.lastName}`,
          type: USER_TYPE_FRANCHISEE,
          permission: "",
          host: getHostTag(),
          requirementId: franchiseeData?.requirementId,
          contactId: franchiseeData._id,
          createdBy: user?.email
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      contactList.push({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        telephone: values.telephone,
        directLine: values.directLine,
        preferredMethod: values.preferredMethod,
        role: values.role,
        isPrimary: values.isPrimary,
        isActive: values.isActive,
        createdBy: user?.email,
        createdAt: new Date()
      });

      logPOCContacts.push({
        updatedBy: user.email,
        updatedAt: new Date(),
        data: contactList
      });

      const result = await put(
        `contact/${franchiseeData._id}`,
        {
          poc_contacts: contactList,
          log_poc_contacts: logPOCContacts
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFranchiseeData(result.data.entity);
      //   setContactList([...contactList]);
      //   setLogPOCContacts([...logPOCContacts]);
      zeeForm.resetFields();
      onClose(false);
    } catch (error) {
      console.log("error ---", error);
      handleNotification(
        "Failed",
        "Unable to create POC Contact now. Please contact admin directly."
      );
    }
    setLoadingModal(false);
  }

  return (
    <Modal
      title="Add POC Contact"
      open={isOpen}
      onOk={() => zeeForm.submit()}
      onCancel={onClose}
      footer={[
        <Button size="large" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          size="large"
          type="primary"
          onClick={() => zeeForm.submit()}
          loading={loadingModal}
        >
          Add
        </Button>
      ]}
    >
      {error && (
        <div>
          {error.type === ERROR_TYPE_CONTINUE && (
            <Alert
              style={{ marginBottom: "20px" }}
              message={error.msg}
              type="info"
              action={
                <Space>
                  <Button
                    icon={<LinkOutlined />}
                    size="small"
                    type="primary"
                    onClick={handleOverWrite}
                  >
                    Link User
                  </Button>
                </Space>
              }
            />
          )}
          {error.type === ERROR_TYPE_QUIT && (
            <Alert
              style={{ marginBottom: "20px" }}
              message={error.msg}
              type="error"
            />
          )}
        </div>
      )}
      <ZorPOCForm form={zeeForm} onSubmit={handleAddPOC} />
    </Modal>
  );
}
