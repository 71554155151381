import { BlockEquipment, BlockUnEquipment } from "@components/underwriting";
import { Loading } from "@components";
import React, { useEffect, useState, useMemo } from "react";
import { Button, notification } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { put } from "@utils/axios";
import "./style.scss";

export default function PanelEquipment({
  franchiseeData,
  setFranchiseeData,
  onNext
}) {
  const { getAccessTokenSilently, user } = useAuth0();
  const [equipFormVal, setEquipFormVal] = useState([]);
  const [unEquipFormVal, setUnEquipFormVal] = useState([]);
  const [locFormVal, setLocFormVal] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEquipFormVal(handleGetEquipFormVal());
    setUnEquipFormVal(handleGetUnEquipFormVal());
  }, [franchiseeData]);

  const locationList = useMemo(() => {
    return (franchiseeData?.commonLocList || []).map((item, index) => ({
      value: index,
      label: `${item.address}, ${item.city}, ${item.state}, ${item.zip}`
    }));
  }, [franchiseeData]);

  function handleGetEquipFormVal() {
    return franchiseeData?.equipmentList || [];
  }

  function handleGetUnEquipFormVal() {
    return franchiseeData?.unScheEquipList || [];
  }

  async function handleSave() {
    setLoading(true);
    try {
      const logEquipmentList = franchiseeData?.logEquipmentList || [];

      logEquipmentList.push({
        updatedBy: user.email,
        updatedAt: new Date(),
        data: equipFormVal
      });

      const logUnScheEquipList = franchiseeData?.logUnScheEquipList || [];

      logUnScheEquipList.push({
        updatedBy: user.email,
        updatedAt: new Date(),
        data: unEquipFormVal
      });
      const token = await getAccessTokenSilently();
      const result = await put(
        `contact/${franchiseeData._id}`,
        {
          equipmentList: equipFormVal,
          logEquipmentList,
          unScheEquipList: unEquipFormVal,
          logUnScheEquipList
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFranchiseeData(result?.data?.entity);
      notification.success({
        message: "Success",
        description: "Equipment Information is successfully updated!",
        duration: 5
      });
    } catch (error) {
      notification.error({
        message: "Failed",
        description:
          "Saving Equipment Information failed! Please contact admin directly.",
        duration: 5
      });
    }

    setLoading(false);
  }

  const isEquipFormValDiff = useMemo(() => {
    const originalStr = JSON.stringify(handleGetEquipFormVal());
    const changedStr = JSON.stringify(equipFormVal);

    return originalStr != changedStr;
  }, [equipFormVal, franchiseeData]);

  const isUnEquipFormValDiff = useMemo(() => {
    const originalStr = JSON.stringify(handleGetUnEquipFormVal());
    const changedStr = JSON.stringify(unEquipFormVal);

    return originalStr != changedStr;
  }, [unEquipFormVal, franchiseeData]);

  return (
    <div className="pan_entity">
      <div className="pan_entity-info">
        <h5 className="pan_entity-info-title">Scheduled Equipment</h5>
        <BlockEquipment
          data={equipFormVal}
          locationList={locationList}
          onChange={setEquipFormVal}
        />
        <div className="pan_entity-info-footer"></div>
      </div>

      <div className="pan_entity-info">
        <h5 className="pan_entity-info-title">Unscheduled Equipment</h5>
        <BlockUnEquipment
          data={unEquipFormVal}
          locationList={locationList}
          onChange={setUnEquipFormVal}
        />
        <div className="pan_entity-info-footer">
          <Button
            onClick={() => {
              if (isEquipFormValDiff || isUnEquipFormValDiff) {
                handleSave();
              } else {
                onNext();
              }
            }}
            size="large"
            type="primary"
          >
            {isEquipFormValDiff || isUnEquipFormValDiff
              ? "Save & Next"
              : "Everything Looks Good. Next"}
          </Button>
        </div>
      </div>

      <Loading loading={loading} />
    </div>
  );
}
