import React, { useState } from "react";
import { post, get, put, postDoc } from "@utils/axios";
import { Button, Select, notification, Modal } from "antd";
import {
  CheckOutlined,
  StopOutlined,
  CalendarOutlined
} from "@ant-design/icons";
import ImageUploadFolder from "../../../../assets/images/upload-folder.png";
import "./style.scss";
import {
  DOC_KEY_COI,
  DOC_LABEL_COI,
  DOC_KEY_COI_EOI,
  DOC_LABEL_COI_EOI,
  DOC_KEY_OTHER,
  DOC_LABEL_OTHER
} from "@assets/const/ui";
import InstructionForm from "../instruction-form";

const VIEW_MODE_UPLOAD = "VIEW_MODE_UPLOAD";
const VIEW_MODE_SUCCESS = "VIEW_MODE_SUCCESS";
const VIEW_MODE_FAIL = "VIEW_MODE_FAIL";

const DOCUMENT_TYPE_LIST = [
  {
    value: DOC_KEY_COI,
    label: DOC_LABEL_COI
  },
  {
    value: DOC_KEY_COI_EOI,
    label: DOC_LABEL_COI_EOI
  },
  {
    value: DOC_KEY_OTHER,
    label: DOC_LABEL_OTHER
  }
];

export default function UploadForm(props) {
  const { franchiseeId, setLoading } = props;
  const [fileList, setFileList] = useState([]);
  const [viewMode, setViewMode] = useState(VIEW_MODE_UPLOAD); // VIEW_MODE_UPLOAD
  const [errorList, setErrorList] = useState([]);
  const [docType, setDocType] = useState(DOC_KEY_COI);
  const [errModal, setErrModal] = useState(false);

  async function handleUpload() {
    setLoading(true);
    try {
      const errorListUpdate = [];
      for (let index = 0; index < fileList.length; index++) {
        const formData = new FormData();
        formData.append("file", fileList[index]);
        formData.append("envMode", process.env.REACT_APP_DOC_UPLOAD_MODE);
        formData.append("docType", docType);
        const res = await postDoc(
          `upload-certificate/${franchiseeId}`,
          formData,
          {
            timeout: 300000
          }
        );
        if (!res?.data?.success) {
          errorListUpdate.push({
            fileName: res?.data?.fileName,
            resultRecog: res?.data?.resultRecog,
            docType: docType
          });
        } else {
          const resultUpload = await post(
            `contact/${franchiseeId}/force-upload-certificate`,
            {
              fileName: res?.data?.fileName,
              resultRecog: res?.data?.resultRecog,
              docType: docType
            }
          );
        }
      }
      setLoading(false);
      if (errorListUpdate.length === 0) {
        setViewMode(VIEW_MODE_SUCCESS);
      } else {
        setErrorList(errorListUpdate);
        setViewMode(VIEW_MODE_FAIL);
        setErrModal(true);
      }
    } catch (ex) {
      console.log(ex);
      setLoading(false);
      handleNotification(
        "Failed",
        "Document uploading is failed. Please contact to admin directly"
      );
    }
  }

  function handleNotification(title, description) {
    notification.open({
      message: title,
      description: description,
      duration: 3
    });
  }

  function handleReUpload() {
    setViewMode(VIEW_MODE_UPLOAD);
  }

  function renderFiles() {
    if (fileList) {
      return Object.values(fileList).map((fileItem, index) => (
        <div className="file-item">
          <span className="title-text">{fileItem.name}</span>
          <CheckOutlined />
        </div>
      ));
    }
  }

  function handleFile(evt) {
    setFileList(evt.target.files);
  }
  async function handleOverwrite() {
    try {
      setLoading(true);
      const res = await post(
        `contact/${franchiseeId}/force-upload-certificate`,
        {
          fileName: errorList[0].fileName,
          resultRecog: errorList[0].resultRecog,
          docType: errorList[0].docType
        }
      );
      errorList.splice(0, 1);
      if (errorList.length > 0) {
        setErrorList([...errorList]);
        setViewMode(VIEW_MODE_FAIL);
      } else {
        setErrorList([]);
        setViewMode(VIEW_MODE_SUCCESS);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  function handleOverwriteCancel() {
    errorList.splice(0, 1);
    if (errorList.length > 0) {
      setErrorList([...errorList]);
      setViewMode(VIEW_MODE_FAIL);
    } else {
      setErrorList([]);
      setViewMode(VIEW_MODE_UPLOAD);
    }
  }

  return (
    <div className="upload_form">
      <div className="upload_form-header">
        <div>
          <b>Document Type:</b>
          <Select
            defaultValue={DOC_KEY_COI}
            onChange={(value) => setDocType(value)}
            options={DOCUMENT_TYPE_LIST}
          />
        </div>
      </div>
      <div className="upload_form-content">
        {viewMode === VIEW_MODE_UPLOAD && (
          <div className="section-upload-main">
            <img src={ImageUploadFolder} />
            <p>
              Drag and Drop Your COI Here
              <br />
              or
            </p>
            <div className="file-list-container">{renderFiles()}</div>
            {fileList.length === 0 && (
              <Button className="btn-add-file" onClick={handleUpload}>
                Add Files
              </Button>
            )}
            <input type="file" onChange={handleFile} multiple />
          </div>
        )}
        {viewMode === VIEW_MODE_SUCCESS && (
          <div className="section-upload-success">
            <div>
              <CheckOutlined className="icon-tick" /> Success!
            </div>
            <div className="section-upload-success_desc">
              Thank you for providing your COI. A rikor agent will reach out
              <br />
              to you if we need anything else, have a great day!
            </div>
          </div>
        )}
        {viewMode === VIEW_MODE_FAIL && (
          <div className="section-upload-error">
            <div className="section-upload-error-wrapper">
              {/* <StopOutlined className="icon-error" /> */}
              Oops…wrong document
            </div>
            <p>
              Please upload a “Certificate of Insurance”
              <a href="https://rikor.ac-page.com/coisample" target="_blank">
                {" "}
                view sample
              </a>
            </p>
          </div>
        )}
      </div>
      <div className="upload_form-footer">
        {viewMode === VIEW_MODE_UPLOAD && fileList.length !== 0 && (
          <Button type="primary" className="upload-btn" onClick={handleUpload}>
            Upload
          </Button>
        )}
        {viewMode === VIEW_MODE_SUCCESS && (
          <Button
            type="primary"
            className="upload-btn"
            onClick={handleReUpload}
          >
            Upload Another Document
          </Button>
        )}
        {viewMode === VIEW_MODE_FAIL && (
          <div className="card-footer-action">
            <Button
              type="primary"
              className="upload-btn"
              onClick={handleOverwrite}
            >
              My business is in Canada
            </Button>
            <Button
              type="secondary"
              className="upload-btn"
              onClick={handleOverwriteCancel}
            >
              Start Over
            </Button>
          </div>
        )}
      </div>
      <Modal open={errModal} footer={false}>
        <InstructionForm onClose={() => setErrModal(false)} />
      </Modal>
    </div>
  );
}
