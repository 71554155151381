import { Table, Button, notification } from "antd";
import React, { useMemo, useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import "./style.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { DOC_NAMING } from "@assets/const/ui";
import Loading from "@components/loading";
import { postDoc, post } from "@utils/axios";

export default function PanelDocument({ franchiseeData, onComplete }) {
  const location = useLocation();
  const history = useHistory();
  const { user, getAccessTokenSilently } = useAuth0();
  const paramQuery = new URLSearchParams(location.search);
  const [docList, setDocList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (paramQuery.get("doc")) {
      const docList = paramQuery.get("doc").split(",");

      setDocList(docList);
      const fileList = new Array(docList.length);
      setFileList(fileList);
    }
  }, [location]);

  async function handleSave() {
    setLoading(true);
    try {
      await onComplete();
      sessionStorage.removeItem("redirect_url");
      history.push("/board/account");
    } catch (error) {
      notification.error({
        message: "Failed",
        description: "Something went wrong! Please contact admin directly.",
        duration: 5
      });
    }
    setLoading(false);
  }

  function handleFile(evt, index, docType) {
    fileList[index] = evt.target.files;
    setFileList([...fileList]);

    handleUpload(evt.target.files, docType);
  }

  async function handleUpload(fileData, docType) {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", fileData[0]);
      formData.append("envMode", process.env.REACT_APP_DOC_UPLOAD_MODE);
      formData.append("docType", docType);
      const res = await postDoc(
        `upload-certificate/${franchiseeData._id}`,
        formData,
        {
          timeout: 300000
        }
      );
      if (!res?.data?.success) {
      } else {
        const resultUpload = await post(
          `contact/${franchiseeData._id}/force-upload-certificate`,
          {
            fileName: res?.data?.fileName,
            resultRecog: res?.data?.resultRecog,
            docType: docType,
            uploadedBy: user?.email
          }
        );
      }
      notification.success({
        message: "Success",
        description: "Document is successfully submitted!",
        duration: 5
      });
    } catch (error) {
      notification.error({
        message: "Failed",
        description:
          "Document uploading is failed. Please contact admin directly",
        duration: 5
      });
    }
    setLoading(false);
  }

  const columns = [
    {
      title: "Document Requested",
      dataIndex: "label",
      key: "label"
    },
    {
      title: "Indicate Which Document is",
      dataIndex: "action",
      key: "action",
      render: (text, record, index) => (
        <div className="pan_doc-item">
          <div className="pan_doc-item-file">
            {!text && <Button>Select Document...</Button>}
            {text && <div>{text[0].name}</div>}
            <input
              type="file"
              onChange={(evt) => handleFile(evt, index, record.type)}
            ></input>
          </div>
          {/* {text && (
            <Button
              type="primary"
              onClick={() => handleUpload(text, record.type)}
            >
              Upload
            </Button>
          )} */}
        </div>
      )
    }
  ];

  const data = useMemo(() => {
    return docList.map((item, index) => ({
      type: item,
      label: DOC_NAMING[item],
      action: fileList[index]
    }));
  }, [docList, fileList]);
  return (
    <div className="pan_doc">
      <Table columns={columns} dataSource={data} />
      <div className="pan_doc-footer">
        <Button onClick={handleSave} size="large" type="primary">
          Save & Submit
        </Button>
      </div>
      <Loading loading={loading} />
    </div>
  );
}
