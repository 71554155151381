import React, { useEffect, useState } from "react";
import UnderWritingQuestion from "../../../admin-board/franchisee-overview/underwriting-question";
import { useAppState } from "@context/index";
import { buildCustomQuestions } from "../../../admin-board/franchisee-detail/under-writing";

export default function PanelQuestion({ franchiseeData }) {
  const [customQuestions, setCustomQuestions] = useState([]);
  const { authSetting, franchisorAdmin } = useAppState();
  useEffect(() => {
    if (franchiseeData) {
      setCustomQuestions(
        buildCustomQuestions(
          franchisorAdmin.custom_questions,
          franchiseeData.customQuestions
        )
      );
    }
  }, [franchiseeData]);
  return (
    <div>
      <UnderWritingQuestion
        onChange={(value) => console.log("custom question ---", value)}
        isCustom={true}
        customQuestions={customQuestions}
        hideSaveButton={true}
        allowChangeQuestions={false}
      />
    </div>
  );
}
