import React, { useState, useEffect } from "react";
import { Input } from "../../../../components";
import { Checkbox, Button } from "antd";
import "./style.scss";

import { useAuth0 } from "@auth0/auth0-react";
import { put } from "../../../../utils/axios";
import moment from "moment";

import { mapFieldWC } from "../../../../utils/form-recog";
import {
  buildAnyAuto,
  buildAllOwnedAuto,
  buildHiredAuto,
  buildScheduledAuto,
  buildNonOwnedAuto,
  buildGeneralLiabilityAdditionalInsurance,
  buildGeneralLiabilitySubrogrationWaved,
  buildEffectDate,
  buildExpireDate,
  buildValueLT,
  buildValueGT,
} from "../../../../utils/build-logic";
import { STATUS_SECTION_APPROVE, DOC_MAX_LIMIT } from "@assets/const/status";

const DEFAULT_VALUE = {
  workers_compensation_el_each_accident: 1000000,
  workers_compensation_other_el_disease_each_employer: 1000000,
  workers_compensation_el_disease_policy_limit: 1000000,
};

export default function WorkersCompForm(props) {
  const { docIndex, franchisorData } = props;

  const { getAccessTokenSilently } = useAuth0();

  const [formVal, setFormVal] = useState(handleGetFormVal());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    if (
      franchiseeData?.finalData?.workers_compensation_form_status ===
      STATUS_SECTION_APPROVE
    ) {
      const finalData = franchiseeData.finalData;

      return {
        workers_compensation_subrogration_waved:
          finalData.workers_compensation_subrogration_waved,
        workers_compensation_el_each_accident:
          finalData.workers_compensation_el_each_accident,
        workers_compensation_other_el_disease_each_employer:
          finalData.workers_compensation_other_el_disease_each_employer,
        workers_compensation_el_disease_policy_limit:
          finalData.workers_compensation_el_disease_policy_limit,
        workers_compensation_policy_number:
          finalData.workers_compensation_policy_number,
        workers_compensation_effective_date:
          finalData.workers_compensation_effective_date,
        workers_compensation_expire_date:
          finalData.workers_compensation_expire_date,
      };
    }

    if (docIndex >= 0 && docIndex !== DOC_MAX_LIMIT) {
      const data = franchiseeData?.recogData[docIndex];
      if (data) {
        return mapFieldWC(data);
      }
    }

    return {};
  }

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  return (
    <div className="report-workers-comp-content">
      <div className="report-workers-comp-content-analysis">
        <label>
          <b>Analysis:</b>
        </label>
        {buildGeneralLiabilitySubrogrationWaved(
          formVal.workers_compensation_subrogration_waved,
          franchisorData.workers_compensation_subrogration_waved,
          franchisorData.workers_compensation_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
        {buildEffectDate(
          formVal.workers_compensation_effective_date,
          franchisorData.workers_compensation_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}

        {buildExpireDate(
          formVal.workers_compensation_expire_date,
          franchisorData.workers_compensation_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
        {buildValueGT(
          formVal.workers_compensation_el_each_accident,
          franchisorData.workers_compensation_el_each_accident,
          franchisorData.workers_compensation_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> EACH ACCIDENT {item.title}
          </div>
        ))}

        {buildValueGT(
          formVal.workers_compensation_other_el_disease_each_employer,
          franchisorData.workers_compensation_other_el_disease_each_employer,
          franchisorData.workers_compensation_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> EACH EMPLOYEE {item.title}
          </div>
        ))}
        {buildValueGT(
          formVal.workers_compensation_el_disease_policy_limit,
          franchisorData.workers_compensation_el_disease_policy_limit,
          franchisorData.workers_compensation_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> POLICY LIMIT {item.title}
          </div>
        ))}
      </div>

      <div className="form-main">
        <div className="panel-left">
          <div className="checkbox-group-container">
            <h5 className="checkbox-group-title">WORKERS COMPENSATION</h5>
            <div className="checkbox-group-content">
              <Checkbox
                checked={formVal.workers_compensation_subrogration_waved}
                onChange={(evt) =>
                  handleChange(
                    "workers_compensation_subrogration_waved",
                    evt.target.checked
                  )
                }
              >
                Waiver Of Subrogation
              </Checkbox>
            </div>
            {buildGeneralLiabilitySubrogrationWaved(
              formVal.workers_compensation_subrogration_waved,
              franchisorData.workers_compensation_subrogration_waved,
              franchisorData.workers_compensation_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
            <div className="alert-container">
              <h4>
                POLICY NUMBER: {formVal.workers_compensation_policy_number}
              </h4>
              <h4>
                EFFECTIVE DATE: {formVal.workers_compensation_effective_date}
              </h4>
              {buildEffectDate(
                formVal.workers_compensation_effective_date,
                franchisorData.workers_compensation_required
              ).map((item) => (
                <div className="issue-report-item">{item.title}</div>
              ))}
              <h4>
                EXPIRATION DATE: {formVal.workers_compensation_expire_date}
              </h4>
              {buildExpireDate(
                formVal.workers_compensation_expire_date,
                franchisorData.workers_compensation_required
              ).map((item) => (
                <div className="issue-report-item">{item.title}</div>
              ))}
            </div>
          </div>
        </div>
        <div className="panel-right">
          <div className="line-item-container">
            <Input
              title="EACH ACCIDENT"
              placeholder="EACH ACCIDENT"
              value={formVal.workers_compensation_el_each_accident}
              onChange={(evt) =>
                handleChange(
                  "workers_compensation_el_each_accident",
                  evt.target.value
                )
              }
            />
            {buildValueGT(
              formVal.workers_compensation_el_each_accident,
              franchisorData.workers_compensation_el_each_accident,
              franchisorData.workers_compensation_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="line-item-container">
            <Input
              title="EACH EMPLOYEE"
              subTitle="( Disease )"
              placeholder="EACH EMPLOYEE"
              value={
                formVal.workers_compensation_other_el_disease_each_employer
              }
              onChange={(evt) =>
                handleChange(
                  "workers_compensation_other_el_disease_each_employer",
                  evt.target.value
                )
              }
            />
            {buildValueGT(
              formVal.workers_compensation_other_el_disease_each_employer,
              franchisorData.workers_compensation_other_el_disease_each_employer,
              franchisorData.workers_compensation_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="line-item-container">
            <Input
              title="POLICY LIMIT "
              subTitle="( Disease )"
              placeholder="POLICY LIMIT"
              value={formVal.workers_compensation_el_disease_policy_limit}
              onChange={(evt) =>
                handleChange(
                  "workers_compensation_el_disease_policy_limit",
                  evt.target.value
                )
              }
            />
            {buildValueGT(
              formVal.workers_compensation_el_disease_policy_limit,
              franchisorData.workers_compensation_el_disease_policy_limit,
              franchisorData.workers_compensation_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
      </div>
      <div className="report-workers-comp-content-mask"></div>
    </div>
  );
}
