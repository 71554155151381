import React from "react";
import { Input } from "@components";
import "./style.scss";
import { DatePicker, Select } from "antd";
import { ENTITY_CODE } from "@assets/const/legal-entity";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { usePlacesWidget } from "react-google-autocomplete";
import { getAddressItem } from "@utils/address";
import { TYPE_INPUT_TAX_ID } from "@assets/const/ui";
import { extractLocationData } from "@utils/google-map";
import { APP_DATE_FORMAT } from "@assets/const/form";
const { Option } = Select;

export default function FormProfile(props) {
  const { data: formVal, onChange } = props;

  function handleChange(key, value) {
    onChange((prev) => ({ ...prev, [key]: value }));
  }

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    options: {
      types: ["address"]
    },
    onPlaceSelected: (place) => {
      const { zip, city, state, address } = extractLocationData(
        place.address_components
      );

      onChange((prev) => ({
        ...prev,
        city: city,
        state: state,
        zip: zip,
        address: address
      }));
    }
  });

  return (
    <div className="form_profile">
      <div className="form-group-two-container">
        <div className="form-group-two-container">
          <Input
            title="First Name"
            type="text"
            required={true}
            value={formVal.first_name}
            onChange={(evt) => handleChange("first_name", evt.target.value)}
          />
          <Input
            title="Last Name"
            type="text"
            required
            value={formVal.last_name}
            onChange={(evt) => handleChange("last_name", evt.target.value)}
          />
        </div>
        <div
          className="form-group-three-container"
          style={{ gridTemplateColumns: "1fr 0.5fr 1fr" }}
        >
          <Input
            title="Entity Name"
            type="text"
            required={true}
            value={formVal.entity_name}
            onChange={(evt) => handleChange("entity_name", evt.target.value)}
          />
          <div>
            <p className="input-title">
              Entity Type<span className="input-required">*</span>
            </p>
            <Select
              size="large"
              style={{ minWidth: "120px", width: "100%" }}
              value={formVal.entity_type}
              onChange={(value) => handleChange("entity_type", value)}
            >
              {ENTITY_CODE.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </div>
          <Input
            title="DBA"
            type="text"
            required
            value={formVal.dba}
            onChange={(evt) => handleChange("dba", evt.target.value)}
          />
        </div>
      </div>

      <div className="form-group-two-container"></div>

      <div className="form-group-two-container">
        <div className="form-group-two-container">
          <Input
            title="Email"
            type="text"
            required
            value={formVal.email}
            onChange={(evt) => handleChange("email", evt.target.value)}
          />
          <Input
            title="Telephone"
            type="text"
            required
            inputType="TYPE_INPUT_PHONE"
            value={formVal.telephone}
            onChange={(evt) => handleChange("telephone", evt.target.value)}
          />
        </div>

        <div className="line-item-container">
          <Input
            ref={ref}
            title="Street Address"
            type="text"
            required
            inputType="TYPE_INPUT_ADDRESS"
            value={formVal.address}
            onChange={(evt) => handleChange("address", evt.target.value)}
          />
        </div>
      </div>

      <div className="form-group-two-container">
        <div className="form-group-two-container">
          <div>
            <p className="input-title">Opening Date</p>
            <DatePicker
              size="large"
              format={APP_DATE_FORMAT}
              value={
                !isEmpty(formVal.opening_date)
                  ? dayjs(formVal.opening_date)
                  : null
              }
              style={{ width: "100%" }}
              onChange={(_, dateString) =>
                handleChange("opening_date", dateString)
              }
            ></DatePicker>
          </div>
          <Input
            title="Tax ID"
            type="text"
            inputType={TYPE_INPUT_TAX_ID}
            value={formVal.tax_id}
            onChange={(evt) => handleChange("tax_id", evt.target.value)}
          />
        </div>
        <div className="form-group-three-container">
          <Input
            title="City"
            type="text"
            required
            value={formVal.city}
            onChange={(evt) => handleChange("city", evt.target.value)}
          />
          <Input
            title="State"
            type="text"
            required
            value={formVal.state}
            onChange={(evt) => handleChange("state", evt.target.value)}
          />
          <Input
            title="Zip"
            type="text"
            required
            value={formVal.zip}
            onChange={(evt) => handleChange("zip", evt.target.value)}
          />
        </div>
      </div>
      <div className="form-group-two-container">
        <div className="form-group-two-container">
          <div>
            <Input
              title="Center Name/Location Number"
              type="text"
              value={formVal.centerName}
              onChange={(evt) => handleChange("centerName", evt.target.value)}
            />
          </div>
          <div>
            <Input
              title="Estimated Annual Revenue"
              type="text"
              value={formVal.annualRevenue}
              onChange={(evt) =>
                handleChange("annualRevenue", evt.target.value)
              }
            />
          </div>
          <div></div>
        </div>
        <div></div>
      </div>
    </div>
  );
}
