import React, { useState, useEffect } from "react";
import { Input } from "../../../../components";
import { Checkbox, Button } from "antd";
import "./style.scss";
import { mapFieldAuto } from "../../../../utils/form-recog";
import {
  buildAnyAuto,
  buildAllOwnedAuto,
  buildHiredAuto,
  buildScheduledAuto,
  buildNonOwnedAuto,
  buildGeneralLiabilityAdditionalInsurance,
  buildGeneralLiabilitySubrogrationWaved,
  buildEffectDate,
  buildExpireDate,
  buildValueLT,
  buildValueGT
} from "../../../../utils/build-logic";
import { STATUS_SECTION_APPROVE, DOC_MAX_LIMIT } from "@assets/const/status";

const DEFAULT_VALUE = {
  automotive_liability_combined_single_limit: 1000000,
  automotive_liability_bodily_injury_pp: 1000
};

export default function AutoForm(props) {
  const { docIndex, franchisorData } = props;

  const [formVal, setFormVal] = useState(handleGetFormVal());

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    if (
      franchiseeData?.finalData?.automotive_liability_form_status ===
      STATUS_SECTION_APPROVE
    ) {
      const finalData = franchiseeData.finalData;

      return {
        automotive_liability_any_auto: finalData.automotive_liability_any_auto,
        automotive_liability_all_owned_autos:
          finalData.automotive_liability_all_owned_autos,
        automotive_liability_category_hired_autos:
          finalData.automotive_liability_category_hired_autos,
        automotive_liability_category_scheduled_autos:
          finalData.automotive_liability_category_scheduled_autos,
        automotive_liability_category_non_owned_autos:
          finalData.automotive_liability_category_non_owned_autos,
        automotive_policy_form_additional_insurance:
          finalData.automotive_policy_form_additional_insurance,
        automotive_policy_form_subrogratrion_waived:
          finalData.automotive_policy_form_subrogratrion_waived,
        automotive_liability_combined_single_limit:
          finalData.automotive_liability_combined_single_limit,
        automotive_liability_bodily_injury_pp:
          finalData.automotive_liability_bodily_injury_pp,
        automotive_liability_policy_number:
          finalData.automotive_liability_policy_number,
        automotive_liability_effective_date:
          finalData.automotive_liability_effective_date,
        automotive_liability_expire_date:
          finalData.automotive_liability_expire_date
      };
    }
    if (docIndex >= 0 && docIndex !== DOC_MAX_LIMIT) {
      const data = franchiseeData?.recogData[docIndex];
      if (data) {
        return mapFieldAuto(data);
      }
    }

    return {};
  }

  const [loading, setLoading] = useState(false);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value
    });
  }

  return (
    <div className="report-auto-content">
      <div className="report-auto-content-analysis">
        <label>
          <b>Analysis:</b>
        </label>
        {buildAnyAuto(
          formVal.automotive_liability_any_auto,
          franchisorData.automotive_liability_any_auto,
          franchisorData.automotive_liability_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}

        {buildAllOwnedAuto(
          formVal.automotive_liability_all_owned_autos,
          franchisorData.automotive_liability_all_owned_autos,
          formVal.automotive_liability_any_auto ||
            (formVal.automotive_liability_category_non_owned_autos &&
              formVal.automotive_liability_category_hired_autos) ||
            (formVal.automotive_liability_category_scheduled_autos &&
              formVal.automotive_liability_category_hired_autos) ||
            (formVal.automotive_liability_category_non_owned_autos &&
              formVal.automotive_liability_category_scheduled_autos),
          franchisorData.automotive_liability_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
        {buildHiredAuto(
          formVal.automotive_liability_category_hired_autos,
          franchisorData.automotive_liability_category_hired_autos,
          formVal.automotive_liability_any_auto,
          franchisorData.automotive_liability_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
        {buildScheduledAuto(
          formVal.automotive_liability_category_scheduled_autos,
          franchisorData.automotive_liability_category_scheduled_autos,
          formVal.automotive_liability_any_auto,
          franchisorData.automotive_liability_required ||
            (formVal.automotive_liability_category_hired_autos &&
              formVal.automotive_liability_category_non_owned_autos)
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
        {buildNonOwnedAuto(
          formVal.automotive_liability_category_non_owned_autos,
          franchisorData.automotive_liability_category_non_owned_autos,
          formVal.automotive_liability_any_auto,
          franchisorData.automotive_liability_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
        {buildGeneralLiabilityAdditionalInsurance(
          formVal.automotive_policy_form_additional_insurance,
          franchisorData.automotive_policy_form_additional_insurance,
          franchisorData.automotive_liability_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
        {buildGeneralLiabilitySubrogrationWaved(
          formVal.automotive_policy_form_subrogratrion_waived,
          franchisorData.automotive_policy_form_subrogratrion_waived,
          franchisorData.automotive_liability_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
        {buildEffectDate(
          formVal.automotive_liability_effective_date,
          franchisorData.automotive_liability_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
        {buildExpireDate(
          formVal.automotive_liability_expire_date,
          franchisorData.automotive_liability_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
        {buildValueGT(
          formVal.automotive_liability_combined_single_limit,
          franchisorData.automotive_liability_combined_single_limit,
          franchisorData.automotive_liability_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> COMBINED SINGLE LIMIT {item.title}
          </div>
        ))}
        {buildValueLT(
          formVal.automotive_liability_bodily_injury_pp,
          franchisorData.automotive_liability_bodily_injury_pp,
          franchisorData.automotive_liability_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> Maximum Required Deductible {item.title}
          </div>
        ))}
      </div>

      <div className="form-main">
        <div className="panel-left">
          <div className="checkbox-group-container">
            <h5 className="checkbox-group-title">AUTOMOBILE LIABLITY</h5>
            <div className="checkbox-group-content">
              <Checkbox
                checked={formVal.automotive_liability_any_auto}
                onChange={(evt) =>
                  handleChange(
                    "automotive_liability_any_auto",
                    evt.target.checked
                  )
                }
              >
                Any Auto
              </Checkbox>
              <Checkbox
                checked={formVal.automotive_liability_all_owned_autos}
                onChange={(evt) =>
                  handleChange(
                    "automotive_liability_all_owned_autos",
                    evt.target.checked
                  )
                }
              >
                All Owned Autos
              </Checkbox>
              <Checkbox
                checked={formVal.automotive_liability_category_hired_autos}
                onChange={(evt) =>
                  handleChange(
                    "automotive_liability_category_hired_autos",
                    evt.target.checked
                  )
                }
              >
                Hired Autos
              </Checkbox>
              <Checkbox
                checked={formVal.automotive_liability_category_scheduled_autos}
                onChange={(evt) =>
                  handleChange(
                    "automotive_liability_category_scheduled_autos",
                    evt.target.checked
                  )
                }
              >
                Scheduled Autos
              </Checkbox>
              <Checkbox
                checked={formVal.automotive_liability_category_non_owned_autos}
                onChange={(evt) =>
                  handleChange(
                    "automotive_liability_category_non_owned_autos",
                    evt.target.checked
                  )
                }
              >
                Non-Owned Autos
              </Checkbox>
            </div>

            {buildAnyAuto(
              formVal.automotive_liability_any_auto,
              franchisorData.automotive_liability_any_auto,
              franchisorData.automotive_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}

            {buildAllOwnedAuto(
              formVal.automotive_liability_all_owned_autos,
              franchisorData.automotive_liability_all_owned_autos,
              formVal.automotive_liability_any_auto ||
                (formVal.automotive_liability_category_non_owned_autos &&
                  formVal.automotive_liability_category_hired_autos) ||
                (formVal.automotive_liability_category_scheduled_autos &&
                  formVal.automotive_liability_category_hired_autos) ||
                (formVal.automotive_liability_category_non_owned_autos &&
                  formVal.automotive_liability_category_scheduled_autos),
              franchisorData.automotive_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
            {buildHiredAuto(
              formVal.automotive_liability_category_hired_autos,
              franchisorData.automotive_liability_category_hired_autos,
              formVal.automotive_liability_any_auto,
              franchisorData.automotive_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
            {buildScheduledAuto(
              formVal.automotive_liability_category_scheduled_autos,
              franchisorData.automotive_liability_category_scheduled_autos,
              formVal.automotive_liability_any_auto,
              franchisorData.automotive_liability_required ||
                (formVal.automotive_liability_category_hired_autos &&
                  formVal.automotive_liability_category_non_owned_autos)
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
            {buildNonOwnedAuto(
              formVal.automotive_liability_category_non_owned_autos,
              franchisorData.automotive_liability_category_non_owned_autos,
              formVal.automotive_liability_any_auto,
              franchisorData.automotive_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="checkbox-container">
            <Checkbox
              checked={formVal.automotive_policy_form_additional_insurance}
              onChange={(evt) =>
                handleChange(
                  "automotive_policy_form_additional_insurance",
                  evt.target.checked
                )
              }
            >
              Additional Insured
            </Checkbox>
            {buildGeneralLiabilityAdditionalInsurance(
              formVal.automotive_policy_form_additional_insurance,
              franchisorData.automotive_policy_form_additional_insurance,
              franchisorData.automotive_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="checkbox-container">
            <Checkbox
              checked={formVal.automotive_policy_form_subrogratrion_waived}
              onChange={(evt) =>
                handleChange(
                  "automotive_policy_form_subrogratrion_waived",
                  evt.target.checked
                )
              }
            >
              Waiver Of Subrogation
            </Checkbox>
            {buildGeneralLiabilitySubrogrationWaved(
              formVal.automotive_policy_form_subrogratrion_waived,
              franchisorData.automotive_policy_form_subrogratrion_waived,
              franchisorData.automotive_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="alert-container">
            <h4>POLICY NUMBER: {formVal.automotive_liability_policy_number}</h4>
            <h4>
              EFFECTIVE DATE: {formVal.automotive_liability_effective_date}
            </h4>
            {buildEffectDate(
              formVal.automotive_liability_effective_date,
              franchisorData.automotive_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
            <h4>EXPIRATION DATE: {formVal.automotive_liability_expire_date}</h4>
            {buildExpireDate(
              formVal.automotive_liability_expire_date,
              franchisorData.automotive_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
        <div className="panel-right">
          <div className="line-item-container">
            <Input
              title="COMBINED SINGLE LIMIT"
              subTitle="( Ea occurrence )"
              placeholder="COMBINED SINGLE LIMIT ( Ea occurrence)"
              value={formVal.automotive_liability_combined_single_limit}
              onChange={(evt) =>
                handleChange(
                  "automotive_liability_combined_single_limit",
                  evt.target.value
                )
              }
            />
            {buildValueGT(
              formVal.automotive_liability_combined_single_limit,
              franchisorData.automotive_liability_combined_single_limit,
              franchisorData.automotive_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="line-item-container">
            <Input
              title="Maximum Required Deductible"
              placeholder="Maximum Required Deductible"
              value={formVal.automotive_liability_bodily_injury_pp}
              onChange={(evt) =>
                handleChange(
                  "automotive_liability_bodily_injury_pp",
                  evt.target.value
                )
              }
            />
            {buildValueLT(
              formVal.automotive_liability_bodily_injury_pp,
              franchisorData.automotive_liability_bodily_injury_pp,
              franchisorData.automotive_liability_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
      </div>
      <div className="report-auto-content-mask"></div>
    </div>
  );
}
