import { BlockOffice, BlockPayroll } from "@components/underwriting";
import { Loading } from "@components";
import React, { useEffect, useState, useMemo } from "react";
import { Button, notification } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { put } from "@utils/axios";
import "./style.scss";

export default function PanelPayroll({
  franchiseeData,
  setFranchiseeData,
  onNext
}) {
  const { getAccessTokenSilently, user } = useAuth0();
  const [formVal, setFormVal] = useState([]);
  const [officeForm, setOfficeForm] = useState([]);
  const [loading, setLoading] = useState(false);

  const locationList = useMemo(() => {
    return (franchiseeData?.commonLocList || []).map((item, index) => ({
      value: index,
      label: `${item.address}, ${item.city}, ${item.state}, ${item.zip}`
    }));
  }, [franchiseeData]);

  useEffect(() => {
    setFormVal(handleGetFormVal());
    setOfficeForm(handleGetOfficeForm());
  }, [franchiseeData]);

  function handleGetFormVal() {
    return franchiseeData?.payrollList || [];
  }

  function handleGetOfficeForm() {
    return franchiseeData?.officeList || [];
  }

  async function handleSave() {
    setLoading(true);
    try {
      const logPayrollList = franchiseeData?.logPayrollList || [];

      logPayrollList.push({
        updatedBy: user.email,
        updatedAt: new Date(),
        data: formVal
      });
      const logOfficeList = franchiseeData?.logOfficeList || [];

      logOfficeList.push({
        updatedBy: user.email,
        updatedAt: new Date(),
        data: officeForm
      });
      const token = await getAccessTokenSilently();
      const result = await put(
        `contact/${franchiseeData._id}`,
        {
          payrollList: formVal,
          logPayrollList,
          officeList: officeForm,
          logOfficeList
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFranchiseeData(result?.data?.entity);
      notification.success({
        message: "Success",
        description: "Payroll Information is successfully updated!",
        duration: 5
      });
    } catch (error) {
      notification.error({
        message: "Failed",
        description:
          "Saving Payroll Information failed! Please contact admin directly.",
        duration: 5
      });
    }
    setLoading(false);
  }

  const isFormValDiff = useMemo(() => {
    const originalStr = JSON.stringify(handleGetFormVal());
    const changedStr = JSON.stringify(formVal);

    return originalStr != changedStr;
  }, [formVal, franchiseeData]);

  const isOfficeFormDiff = useMemo(() => {
    const originalStr = JSON.stringify(handleGetOfficeForm());
    const changedStr = JSON.stringify(officeForm);

    return originalStr != changedStr;
  }, [officeForm, franchiseeData]);

  return (
    <div className="pan_driver">
      <div className="pan_driver-info">
        <h5 className="pan_driver-info-title">Payroll</h5>
        <BlockPayroll
          data={formVal}
          locationList={locationList}
          onChange={setFormVal}
        />
        <br />

        <h5 className="pan_driver-info-title">Owners & Officers</h5>
        <BlockOffice data={officeForm} onChange={setOfficeForm} />

        <div className="pan_driver-info-footer">
          <Button
            onClick={() => {
              if (isFormValDiff || isOfficeFormDiff) {
                handleSave();
              } else {
                onNext();
              }
            }}
            size="large"
            type="primary"
          >
            {isFormValDiff || isOfficeFormDiff
              ? "Save & Next"
              : "Everything Looks Good. Next"}
          </Button>
        </div>
      </div>

      <Loading loading={loading} />
    </div>
  );
}
