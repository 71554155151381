import React from "react";
import "./style.scss";
import { Switch, Route } from "react-router-dom";
import FranchiseeUpload from "./franchisee-upload";
import FranchiseeDetailsReport from "./franchisee-details-report";

export default function FranchiseeSection() {
  return (
    <div className="franchisor-dashboard-container">
      {/* <Header screen="franchisee-section" /> */}
      <div className="dashboard-wrapper">
        <Switch>
          <Route path="/coiportal/add/:id" component={FranchiseeUpload} />
          <Route
            path="/coiportal/report/:id"
            component={FranchiseeDetailsReport}
          />
        </Switch>
      </div>
    </div>
  );
}
