import React, { useState, useEffect } from "react";
import { Input } from "../../../../components";
import { Radio, Checkbox } from "antd";
import "./style.scss";
import {
  buildEffectDate,
  buildExpireDate,
  buildFormType,
  buildRecoveryType,
  buildValueGT,
  buildValueLT,
  reportProIns,
} from "../../../../utils/build-logic";
import { STATUS_SECTION_APPROVE, DOC_MAX_LIMIT } from "@assets/const/status";
import { mapFieldProIns } from "@utils/form-recog";

import {
  FORM_TYPE_SPECIAL,
  FORM_TYPE_BROAD,
  FORM_TYPE_BASIC,
  FORM_LOSS_RECOVERY_TYPE_COST,
  FORM_LOSS_RECOVERY_TYPE_CASH,
} from "@assets/const/form";

export default function ProInsForm(props) {
  const { docIndex, franchisorData } = props;

  const [formVal, setFormVal] = useState(handleGetFormVal());

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    if (
      franchiseeData?.finalData?.pro_ins_form_status === STATUS_SECTION_APPROVE
    ) {
      const finalData = franchiseeData.finalData;

      return {
        pro_ins_is_bus_per_pro: finalData.pro_ins_is_bus_per_pro,
        pro_ins_bus_per_pro: finalData.pro_ins_bus_per_pro,
        pro_ins_is_ten_imp: finalData.pro_ins_is_ten_imp,
        pro_ins_ten_imp: finalData.pro_ins_ten_imp,
        pro_ins_is_bui_amo: finalData.pro_ins_is_bui_amo,
        pro_ins_bui_amo: finalData.pro_ins_bui_amo,
        pro_ins_is_bus_int: finalData.pro_ins_is_bus_int,
        pro_ins_bus_int: finalData.pro_ins_bus_int,
        pro_ins_is_act_los: finalData.pro_ins_is_act_los,
        pro_ins_act_los: finalData.pro_ins_act_los,
        pro_ins_is_mon_lim: finalData.pro_ins_is_mon_lim,
        pro_ins_mon_lim: finalData.pro_ins_mon_lim,
        pro_ins_is_co_ins: finalData.pro_ins_is_co_ins,
        pro_ins_co_ins: finalData.pro_ins_co_ins,
        pro_ins_is_agg_val: finalData.pro_ins_is_agg_val,
        pro_ins_agg_val: finalData.pro_ins_agg_val,
        pro_ins_form_type: finalData.pro_ins_form_type,
        pro_ins_los_rec_type: finalData.pro_ins_los_rec_type,
        pro_ins_policy_number: finalData.pro_ins_policy_number,
        pro_ins_effective_date: finalData.pro_ins_effective_date,
        pro_ins_expire_date: finalData.pro_ins_expire_date,
      };
    }

    if (docIndex >= 0 && docIndex !== DOC_MAX_LIMIT) {
      const data = franchiseeData?.recogData[docIndex][0]?.fields;
      if (data) {
        return mapFieldProIns(data);
      }
    }

    return {};
  }

  const [loading, setLoading] = useState(false);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  return (
    <div className="report-pro_ins-content">
      <div className="report-pro_ins-content-analysis">
        <label>
          <b>Analysis:</b>
        </label>
        {reportProIns(formVal, franchisorData).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
      </div>

      <div className="form-main">
        <div className="panel-left">
          {/* <div className="form-group-two-container checkbox-container">
            <div>
              <h5 className="checkbox-title">Included Coverages</h5>
              <div>
                <div>
                  <Checkbox
                    checked={formVal.pro_ins_is_bus_per_pro}
                    onChange={(evt) =>
                      handleChange("pro_ins_is_bus_per_pro", evt.target.checked)
                    }
                  >
                    Business Personal Property
                  </Checkbox>
                </div>
                <div>
                  <Checkbox
                    checked={formVal.pro_ins_is_ten_imp}
                    onChange={(evt) =>
                      handleChange("pro_ins_is_ten_imp", evt.target.checked)
                    }
                  >
                    Tenant Improvement
                  </Checkbox>
                </div>
                <div>
                  <Checkbox
                    checked={formVal.pro_ins_is_bui_amo}
                    onChange={(evt) =>
                      handleChange("pro_ins_is_bui_amo", evt.target.checked)
                    }
                  >
                    Building
                  </Checkbox>
                </div>
                <div>
                  <Checkbox
                    checked={formVal.pro_ins_is_bus_int}
                    onChange={(evt) =>
                      handleChange("pro_ins_is_bus_int", evt.target.checked)
                    }
                  >
                    Business Interruption
                  </Checkbox>
                </div>
              </div>
            </div>
            <div>
              <h5 className="checkbox-title">Business Interruption</h5>
              <div>
                <div>
                  <Checkbox
                    checked={formVal.pro_ins_is_act_los}
                    onChange={(evt) =>
                      handleChange("pro_ins_is_act_los", evt.target.checked)
                    }
                  >
                    Actual Loss Sustained
                  </Checkbox>
                </div>
                <div>
                  <Checkbox
                    checked={formVal.pro_ins_is_mon_lim}
                    onChange={(evt) =>
                      handleChange("pro_ins_is_mon_lim", evt.target.checked)
                    }
                  >
                    Monthly Limitation
                  </Checkbox>
                </div>
                <div>
                  <Checkbox
                    checked={formVal.pro_ins_is_co_ins}
                    onChange={(evt) =>
                      handleChange("pro_ins_is_co_ins", evt.target.checked)
                    }
                  >
                    Co-Insurance
                  </Checkbox>
                </div>
                <div>
                  <Checkbox
                    checked={formVal.pro_ins_is_agg_val}
                    onChange={(evt) =>
                      handleChange("pro_ins_is_agg_val", evt.target.checked)
                    }
                  >
                    Agreed Value
                  </Checkbox>
                </div>
              </div>
            </div>
          </div> */}

          <div className="radio-container">
            <h5 className="radio-title">FORM TYPE</h5>
            <Radio.Group
              onChange={(evt) =>
                handleChange("pro_ins_form_type", evt.target.value)
              }
              value={formVal.pro_ins_form_type}
            >
              <Radio value={FORM_TYPE_SPECIAL}>Special</Radio>
              <Radio value={FORM_TYPE_BROAD}>Broad</Radio>
              <Radio value={FORM_TYPE_BASIC}>Basic</Radio>
            </Radio.Group>
            {buildFormType(
              formVal.pro_ins_form_type,
              franchisorData.pro_ins_form_type,
              franchisorData.pro_ins_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="radio-container">
            <h5 className="radio-title">LOSS RECOVERY TYPE</h5>
            <Radio.Group
              onChange={(evt) =>
                handleChange("pro_ins_los_rec_type", evt.target.value)
              }
              value={formVal.pro_ins_los_rec_type}
            >
              <Radio value={FORM_LOSS_RECOVERY_TYPE_COST}>
                Replacement Cost
              </Radio>
              <Radio value={FORM_LOSS_RECOVERY_TYPE_CASH}>
                Actual Cash Value
              </Radio>
            </Radio.Group>
            {buildRecoveryType(
              formVal.pro_ins_los_rec_type,
              franchisorData.pro_ins_los_rec_type,
              franchisorData.pro_ins_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>

          <div className="alert-container">
            <h4>POLICY NUMBER: {formVal.pro_ins_policy_number}</h4>
            <h4>EFFECTIVE DATE: {formVal.pro_ins_effective_date}</h4>
            {buildEffectDate(
              formVal.pro_ins_effective_date,
              franchisorData?.pro_ins_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
            <h4>EXPIRATION DATE: {formVal.pro_ins_expire_date}</h4>
            {buildExpireDate(
              formVal.pro_ins_expire_date,
              franchisorData.pro_ins_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
        <div className="panel-right">
          {/* {formVal.pro_ins_is_bus_per_pro && ( */}
          <div className="line-item-container">
            <Input
              title="BUSINESS PERSONAL PROPERTY"
              type="number"
              placeholder="BUSINESS PERSONAL PROPERTY"
              value={formVal.pro_ins_bus_per_pro}
              onChange={(evt) =>
                handleChange("pro_ins_bus_per_pro", evt.target.value)
              }
            />
            {buildValueGT(
              formVal.pro_ins_bus_per_pro,
              franchisorData.pro_ins_bus_per_pro,
              franchisorData?.pro_ins_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          {/* )}
          {formVal.pro_ins_is_ten_imp && ( */}
          <div className="line-item-container">
            <Input
              title="TENANT IMPROVEMENTS"
              type="number"
              placeholder="TENANT IMPROVEMENTS"
              value={formVal.pro_ins_ten_imp}
              onChange={(evt) =>
                handleChange("pro_ins_ten_imp", evt.target.value)
              }
            />
            {buildValueGT(
              formVal.pro_ins_ten_imp,
              franchisorData.pro_ins_ten_imp,
              franchisorData?.pro_ins_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          {/* )}

          {formVal.pro_ins_is_bui_amo && ( */}
          <div className="line-item-container">
            <Input
              title="BUILDING AMOUNT"
              type="number"
              placeholder="BUILDING AMOUNT"
              value={formVal.pro_ins_bui_amo}
              onChange={(evt) =>
                handleChange("pro_ins_bui_amo", evt.target.value)
              }
            />
            {buildValueGT(
              formVal.pro_ins_bui_amo,
              franchisorData.pro_ins_bui_amo,
              franchisorData?.pro_ins_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          {/* )}

          {formVal.pro_ins_is_bus_int && ( */}
          <div className="line-item-container">
            <Input
              title="BUSINESS INTERRUPTION"
              type="number"
              placeholder="BUSINESS INTERRUPTION"
              value={formVal.pro_ins_bus_int}
              onChange={(evt) =>
                handleChange("pro_ins_bus_int", evt.target.value)
              }
            />
            {buildValueGT(
              formVal.pro_ins_bus_int,
              franchisorData.pro_ins_bus_int,
              franchisorData?.pro_ins_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          {/* )}
          {formVal.pro_ins_is_act_los && ( */}
          <div className="line-item-container">
            <Input
              title="ACTUAL LOSS SUSTAINED"
              type="number"
              placeholder="ACTUAL LOSS SUSTAINED"
              value={formVal.pro_ins_act_los}
              onChange={(evt) =>
                handleChange("pro_ins_act_los", evt.target.value)
              }
            />
            {buildValueGT(
              formVal.pro_ins_act_los,
              franchisorData.pro_ins_act_los,
              franchisorData?.pro_ins_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          {/* )}
          {formVal.pro_ins_is_mon_lim && ( */}
          <div className="line-item-container">
            <Input
              title="MONTHLY LIMITATION"
              type="number"
              placeholder="MONTHLY LIMITATION"
              value={formVal.pro_ins_mon_lim}
              onChange={(evt) =>
                handleChange("pro_ins_mon_lim", evt.target.value)
              }
            />
            {buildValueGT(
              formVal.pro_ins_mon_lim,
              franchisorData.pro_ins_mon_lim,
              franchisorData?.pro_ins_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          {/* )}
          {formVal.pro_ins_is_co_ins && ( */}
          <div className="line-item-container">
            <Input
              title="CO-INSURANCE"
              type="number"
              placeholder="CO-INSURANCE"
              value={formVal.pro_ins_co_ins}
              onChange={(evt) =>
                handleChange("pro_ins_co_ins", evt.target.value)
              }
            />
            {buildValueGT(
              formVal.pro_ins_co_ins,
              franchisorData.pro_ins_co_ins,
              franchisorData?.pro_ins_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          {/* )}

          {formVal.pro_ins_is_agg_val && ( */}
          <div className="line-item-container">
            <Input
              title="AGREED VALUE"
              type="number"
              placeholder="AGREED VALUE"
              value={formVal.pro_ins_agg_val}
              onChange={(evt) =>
                handleChange("pro_ins_agg_val", evt.target.value)
              }
            />
            {buildValueGT(
              formVal.pro_ins_agg_val,
              franchisorData.pro_ins_agg_val,
              franchisorData?.pro_ins_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          {/* )} */}
        </div>
      </div>
      <div className="report-pro_ins-content-mask"></div>
    </div>
  );
}
