import React, { useState } from "react";
import ImageUploadFolder from "@assets/images/upload-folder.png";
import { Button, notification, Select } from "antd";
import { CheckOutlined, StopOutlined } from "@ant-design/icons";
import { Loading } from "@components";
import { useParams } from "react-router-dom";
import "./style.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { post, postDoc } from "@utils/axios";
import {
  DOC_KEY_COI,
  DOC_LABEL_COI,
  DOC_KEY_COI_EOI,
  DOC_LABEL_COI_EOI,
  DOC_KEY_OTHER,
  DOC_LABEL_OTHER
} from "@assets/const/ui";
import { VIEW_SOURCE_ZEE } from "@assets/const/ui";

const VIEW_MODE_UPLOAD = "VIEW_MODE_UPLOAD";
const VIEW_MODE_SUCCESS = "VIEW_MODE_SUCCESS";
const VIEW_MODE_ERROR = "VIEW_MODE_ERROR";

const DOCUMENT_TYPE_LIST = [
  {
    value: DOC_KEY_COI,
    label: DOC_LABEL_COI
  },
  {
    value: DOC_KEY_COI_EOI,
    label: DOC_LABEL_COI_EOI
  },
  {
    value: DOC_KEY_OTHER,
    label: DOC_LABEL_OTHER
  }
];

export default function InsuranceUpload({ viewSource, zeeId }) {
  const { getAccessTokenSilently } = useAuth0();

  const param = useParams();
  const [fileList, setFileList] = useState([]);
  const [modalFlag, setModalFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState(VIEW_MODE_UPLOAD);
  const [errorList, setErrorList] = useState([]);
  const [docType, setDocType] = useState(DOC_KEY_COI);

  async function handleOverwrite() {
    try {
      const franchiseeId = viewSource === VIEW_SOURCE_ZEE ? zeeId : param.id;
      setLoading(true);
      const res = await post(
        `contact/${franchiseeId}/force-upload-certificate`,
        {
          fileName: errorList[0].fileName,
          resultRecog: errorList[0].resultRecog,
          docType: errorList[0].docType
        }
      );
      errorList.splice(0, 1);
      if (errorList.length > 0) {
        setErrorList([...errorList]);
        setViewMode(VIEW_MODE_ERROR);
      } else {
        setErrorList([]);
        setViewMode(VIEW_MODE_SUCCESS);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  function handleOverwriteCancel() {
    errorList.splice(0, 1);
    if (errorList.length > 0) {
      setErrorList([...errorList]);
      setViewMode(VIEW_MODE_ERROR);
    } else {
      setErrorList([]);
      setViewMode(VIEW_MODE_UPLOAD);
    }
  }

  async function handleUpload() {
    setLoading(true);
    try {
      const franchiseeId = viewSource === VIEW_SOURCE_ZEE ? zeeId : param.id;
      const errorListUpdate = [];
      for (let index = 0; index < fileList.length; index++) {
        const formData = new FormData();
        formData.append("file", fileList[index]);
        formData.append("envMode", process.env.REACT_APP_DOC_UPLOAD_MODE);
        formData.append("docType", docType);
        const res = await postDoc(
          `upload-certificate/${franchiseeId}`,
          formData,
          {
            timeout: 300000
          }
        );
        if (!res?.data?.success) {
          errorListUpdate.push({
            fileName: res?.data?.fileName,
            resultRecog: res?.data?.resultRecog,
            docType: docType
          });
        } else {
          const resultUpload = await post(
            `contact/${franchiseeId}/force-upload-certificate`,
            {
              fileName: res?.data?.fileName,
              resultRecog: res?.data?.resultRecog,
              docType: docType
            }
          );
        }
      }
      setLoading(false);
      if (errorListUpdate.length === 0) {
        setViewMode(VIEW_MODE_SUCCESS);
      } else {
        setErrorList(errorListUpdate);
        setViewMode(VIEW_MODE_ERROR);
      }
    } catch (ex) {
      console.log(ex);
      setLoading(false);
      handleNotification(
        "Failed",
        "Document uploading is failed. Please contact to admin directly"
      );
    }
  }

  function handleNotification(title, description) {
    notification.open({
      message: title,
      description: description,
      duration: 3
    });
  }

  function handleReUpload() {
    setViewMode(VIEW_MODE_UPLOAD);
  }

  function renderFiles() {
    if (fileList) {
      return Object.values(fileList).map((fileItem, index) => (
        <div className="file-item">
          <span className="title-text">{fileItem.name}</span>
          <CheckOutlined />
        </div>
      ));
    }
  }

  function handleFile(evt) {
    setFileList(evt.target.files);
  }

  return (
    <div className="insurance-upload-container">
      <div className="card">
        <div className="card-header">
          <h3>Upload Certificate of Insurance</h3>
          <div className="card-header-action">
            <b>Document Type:</b>
            <Select
              defaultValue={DOC_KEY_COI}
              onChange={(value) => setDocType(value)}
              options={DOCUMENT_TYPE_LIST}
            />
          </div>
        </div>
        <div className="card-content">
          {viewMode === VIEW_MODE_UPLOAD && (
            <div className="upload-container">
              <img src={ImageUploadFolder} />
              <p>
                Drag and Drop Your PDF Here
                <br />
                or
              </p>
              <div className="file-list-container">{renderFiles()}</div>
              {fileList.length === 0 && (
                <Button className="btn-add-file" onClick={handleUpload}>
                  Add Files
                </Button>
              )}
              <input type="file" onChange={handleFile} multiple />
            </div>
          )}
          {viewMode === VIEW_MODE_SUCCESS && (
            <div className="success-container">
              <CheckOutlined className="icon-tick" /> Success!
            </div>
          )}
          {viewMode === VIEW_MODE_ERROR && (
            <div className="error-container">
              <div className="error-container-wrapper">
                <StopOutlined className="icon-error" />
                Wrong Form
              </div>
              <p>
                Incorrect form. Please upload a Certificate of Insurance. The
                correct document will be labeled ACORD 25.
                <br />
                To view a sample Certificate of Insurance,
                <a href="https://rikor.ac-page.com/coisample" target="_blank">
                  Click Here
                </a>
              </p>
            </div>
          )}
        </div>
        <div className="card-footer">
          {viewMode === VIEW_MODE_UPLOAD && fileList.length !== 0 && (
            <Button
              type="primary"
              className="upload-btn"
              onClick={handleUpload}
            >
              Upload
            </Button>
          )}
          {viewMode === VIEW_MODE_SUCCESS && (
            <Button
              type="primary"
              className="upload-btn"
              onClick={handleReUpload}
            >
              Replace
            </Button>
          )}
          {viewMode === VIEW_MODE_ERROR && (
            <div className="card-footer-action">
              <Button
                type="primary"
                className="upload-btn"
                onClick={handleOverwrite}
              >
                My business is in Canada
              </Button>
              <Button
                type="secondary"
                className="upload-btn"
                onClick={handleOverwriteCancel}
              >
                Try to Upload a Different Document
              </Button>
            </div>
          )}
        </div>
      </div>
      <Loading loading={loading} />
    </div>
  );
}
