import React, { useState, useEffect } from "react";
import { Input } from "../../../../components";
import { Radio } from "antd";
import "./style.scss";
import {
  FORM_LOSS_DISCOVERED,
  FORM_LOSS_SUSTAINED,
} from "../../../../assets/const/form";
import {
  buildEffectDate,
  buildExpireDate,
  buildValueGT,
  buildGarageDirectPrimary,
} from "../../../../utils/build-logic";
import { STATUS_SECTION_APPROVE, DOC_MAX_LIMIT } from "@assets/const/status";
import { mapFieldGarage } from "@utils/form-recog";

export default function GarageForm(props) {
  const { docIndex, franchisorData } = props;

  const [formVal, setFormVal] = useState(handleGetFormVal());

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    if (
      franchiseeData?.finalData?.garage_form_status === STATUS_SECTION_APPROVE
    ) {
      const finalData = franchiseeData.finalData;

      return {
        garage_comp: finalData.garage_comp,
        garage_coll: finalData.garage_coll,
        garage_direct: finalData.garage_direct,
        garage_policy_number: finalData.garage_policy_number,
        garage_effective_date: finalData.garage_effective_date,
        garage_expire_date: finalData.garage_expire_date,
      };
    }

    if (docIndex >= 0 && docIndex !== DOC_MAX_LIMIT) {
      const data = franchiseeData?.recogData[docIndex];
      if (data) {
        return mapFieldGarage(data);
      }
    }

    return {};
  }

  const [loading, setLoading] = useState(false);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  return (
    <div className="report-garage-content">
      <div className="report-garage-content-analysis">
        <label>
          <b>Analysis:</b>
        </label>
        {buildGarageDirectPrimary(
          formVal.garage_direct,
          franchisorData.garage_direct,
          franchisorData.garage_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}

        {buildEffectDate(
          formVal.garage_effective_date,
          franchisorData.garage_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}

        {buildExpireDate(
          formVal.garage_expire_date,
          franchisorData.garage_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}

        {buildValueGT(
          formVal.garage_comp,
          franchisorData.garage_comp,
          franchisorData.garage_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}

        {buildValueGT(
          formVal.garage_coll,
          franchisorData.garage_coll,
          franchisorData.garage_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
      </div>

      <div className="form-main">
        <div className="panel-left">
          <div className="radio-container">
            <Radio.Group
              onChange={(evt) =>
                handleChange("garage_direct", evt.target.value)
              }
              value={formVal.garage_direct}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
            {buildGarageDirectPrimary(
              formVal.garage_direct,
              franchisorData.garage_direct,
              franchisorData.garage_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>

          <div className="alert-container">
            <h4>POLICY NUMBER: {formVal.garage_policy_number}</h4>
            <h4>EFFECTIVE DATE: {formVal.garage_effective_date}</h4>
            {buildEffectDate(
              formVal.garage_effective_date,
              franchisorData.garage_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
            <h4>EXPIRATION DATE: {formVal.garage_expire_date}</h4>
            {buildExpireDate(
              formVal.garage_expire_date,
              franchisorData.garage_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
        <div className="panel-right">
          <div className="line-item-container">
            <Input
              title="COMPREHENSIVE"
              placeholder="COMPREHENSIVE"
              value={formVal.garage_comp}
              onChange={(evt) => handleChange("garage_comp", evt.target.value)}
            />
            {buildValueGT(
              formVal.garage_comp,
              franchisorData.garage_comp,
              franchisorData.garage_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="line-item-container">
            <Input
              title="COLLISSION"
              placeholder="COLLISSION"
              value={formVal.garage_coll}
              onChange={(evt) => handleChange("garage_coll", evt.target.value)}
            />
            {buildValueGT(
              formVal.garage_coll,
              franchisorData.garage_coll,
              franchisorData.garage_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
      </div>
      <div className="report-garage-content-mask"></div>
    </div>
  );
}
