import { BlockDriver } from "@components/underwriting";
import { Loading } from "@components";
import React, { useEffect, useState, useMemo } from "react";
import { Button, notification } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { put } from "@utils/axios";
import "./style.scss";

export default function PanelDriverInfo({
  franchiseeData,
  setFranchiseeData,
  onNext
}) {
  const { getAccessTokenSilently, user } = useAuth0();
  const [formVal, setFormVal] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [franchiseeData]);

  function handleGetFormVal() {
    return franchiseeData?.driverList || [];
  }

  async function handleSave() {
    setLoading(true);
    try {
      const logDriverList = franchiseeData?.logDriverList || [];

      logDriverList.push({
        updatedBy: user.email,
        updatedAt: new Date(),
        data: formVal
      });
      const token = await getAccessTokenSilently();
      const result = await put(
        `contact/${franchiseeData._id}`,
        {
          driverList: formVal,
          logDriverList
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFranchiseeData(result?.data?.entity);
      notification.success({
        message: "Success",
        description: "Driver Information is successfully updated!",
        duration: 5
      });
    } catch (error) {
      notification.error({
        message: "Failed",
        description:
          "Saving Driver Information failed! Please contact admin directly.",
        duration: 5
      });
    }
    setLoading(false);
  }

  const isFormValDiff = useMemo(() => {
    const originalStr = JSON.stringify(handleGetFormVal());
    const changedStr = JSON.stringify(formVal);

    return originalStr != changedStr;
  }, [formVal, franchiseeData]);

  return (
    <div className="pan_driver">
      <div className="pan_driver-info">
        <BlockDriver data={formVal} onChange={setFormVal} />
        <div className="pan_driver-info-footer">
          <Button
            onClick={() => {
              if (isFormValDiff) {
                handleSave();
              } else {
                onNext();
              }
            }}
            size="large"
            type="primary"
          >
            {isFormValDiff ? "Save & Next" : "Everything Looks Good. Next"}
          </Button>
        </div>
      </div>

      <Loading loading={loading} />
    </div>
  );
}
