import React from "react";
import { Input } from "@components";
import { Button, Radio } from "antd";
import _ from "lodash";
import dayjs from "dayjs";
import { APP_DATE_FORMAT } from "@assets/const/form";
import { DeleteOutlined } from "@ant-design/icons";
import "./style.scss";

export default function BlockOffice({ data, onChange, disabled }) {
  function handleChangeOfficeInfo(index, key, value) {
    const newFormVal = _.cloneDeep(data);
    newFormVal[index][key] = value;
    onChange((prev) => newFormVal);
  }

  function handleAddNewOffice(index) {
    const newFormVal = _.cloneDeep(data);
    newFormVal.push({
      full_name: "",
      name_type: null,
      payroll: "",
      class_code: "",
      owner_ship: "",
      desc: ""
    });
    onChange((prev) => newFormVal);
  }

  function handleDeleteOffice(index) {
    if (data.length === 0) return;
    const newFormVal = _.cloneDeep(data);
    newFormVal.splice(index, 1);
    onChange((prev) => newFormVal);
  }

  function renderOfficeInfo(index, office) {
    return (
      <div key={`office-${index}`} className="block_off-item">
        <div>
          <div className="form-group-five-container">
            <Input
              title="Full Name"
              type="text"
              value={office.full_name}
              required
              onChange={(evt) =>
                handleChangeOfficeInfo(index, "full_name", evt.target.value)
              }
            />
            <Input
              title="Class Code"
              type="text"
              value={office.class_code}
              required
              onChange={(evt) =>
                handleChangeOfficeInfo(index, "class_code", evt.target.value)
              }
            />
            <Input
              title="Description"
              type="text"
              value={office.desc}
              required
              onChange={(evt) =>
                handleChangeOfficeInfo(index, "desc", evt.target.value)
              }
            />
            <Input
              title="Payroll"
              type="text"
              value={office.payroll}
              required
              onChange={(evt) =>
                handleChangeOfficeInfo(index, "payroll", evt.target.value)
              }
            />
            <Input
              title="Ownership %"
              type="text"
              value={office.owner_ship}
              required
              onChange={(evt) =>
                handleChangeOfficeInfo(index, "owner_ship", evt.target.value)
              }
            />
          </div>
          <div className="block_off-item-footer">
            <Radio.Group
              onChange={(evt) =>
                handleChangeOfficeInfo(index, "name_type", evt.target.value)
              }
              value={office.name_type}
            >
              <Radio value={true}>Excluded</Radio>
              <Radio value={false}>Included</Radio>
            </Radio.Group>
          </div>
        </div>

        <div className="block_off-item-action">
          {!disabled && (
            <Button
              className="block_off-item-action-btn"
              onClick={() => handleDeleteOffice(index)}
            >
              <DeleteOutlined />
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="block_off">
      {data.map((office, index) => renderOfficeInfo(index, office))}
      {!disabled && (
        <div className="admin_franchisee_under-section-footer">
          <Button
            className="admin_franchisee_under-section-footer-link"
            onClick={handleAddNewOffice}
          >
            Add Owner
          </Button>
        </div>
      )}
    </div>
  );
}
