import React, { useState, useEffect } from "react";
import { Input } from "../../../../components";
import { Radio } from "antd";
import "./style.scss";
import {
  FORM_CLAIMS_MADE,
  FORM_OCCURRENCE,
  FORM_UMBRELLA_LIABILITY,
  FORM_EXCESS_LIABILITY,
  FORM_DEDUCTIBLE,
  FORM_RETENTION,
  FORM_NONE,
} from "../../../../assets/const/form";

import { mapFieldUmbrella } from "../../../../utils/form-recog";
import {
  buildEffectDate,
  buildExpireDate,
  buildValueGT,
  buildUmbrellaSubrogrationLiability,
  buildUmbrellaSubrogrationActivity,
  buildUmbrellaSubrogrationStatus,
} from "../../../../utils/build-logic";
import { STATUS_SECTION_APPROVE, DOC_MAX_LIMIT } from "@assets/const/status";

export default function UmbrellaForm(props) {
  const { docIndex, franchisorData } = props;

  const [formVal, setFormVal] = useState(handleGetFormVal());

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    if (
      franchiseeData?.finalData?.umbrella_subrogration_form_status ===
      STATUS_SECTION_APPROVE
    ) {
      const finalData = franchiseeData.finalData;

      return {
        umbrella_subrogration_each_occurance:
          finalData.umbrella_subrogration_each_occurance,
        umbrella_subrogration_aggregate:
          finalData.umbrella_subrogration_aggregate,
        umbrella_subrogration_liability:
          finalData.umbrella_subrogration_liability,
        umbrella_subrogration_activity:
          finalData.umbrella_subrogration_activity,
        umbrella_subrogration_status: finalData.umbrella_subrogration_status,
        umbrella_subrogration_policy_number:
          finalData.umbrella_subrogration_policy_number,
        umbrella_subrogration_effective_date:
          finalData.umbrella_subrogration_effective_date,
        umbrella_subrogration_expire_date:
          finalData.umbrella_subrogration_expire_date,
      };
    }

    if (docIndex >= 0 && docIndex !== DOC_MAX_LIMIT) {
      const data = franchiseeData?.recogData[docIndex];
      if (data) {
        return mapFieldUmbrella(data);
      }
    }

    return {};
  }

  const [loading, setLoading] = useState(false);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  return (
    <div className="report-umbrella-content">
      <div className="report-umbrella-content-analysis">
        <label>
          <b>Analysis:</b>
        </label>
        {buildUmbrellaSubrogrationLiability(
          formVal.umbrella_subrogration_liability,
          franchisorData.umbrella_subrogration_liability,
          franchisorData.umbrella_subrogration_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
        {buildUmbrellaSubrogrationActivity(
          formVal.umbrella_subrogration_activity,
          franchisorData.umbrella_subrogration_activity,
          franchisorData.umbrella_subrogration_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
        {buildUmbrellaSubrogrationStatus(
          formVal.umbrella_subrogration_status,
          franchisorData.umbrella_subrogration_status,
          franchisorData.umbrella_subrogration_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
        {buildEffectDate(
          formVal.umbrella_subrogration_effective_date,
          franchisorData.umbrella_subrogration_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
        {buildExpireDate(
          formVal.umbrella_subrogration_expire_date,
          franchisorData.umbrella_subrogration_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
        {buildValueGT(
          formVal.umbrella_subrogration_each_occurance,
          franchisorData.umbrella_subrogration_each_occurance,
          franchisorData.umbrella_subrogration_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> EACH OCCURRENCE {item.title}
          </div>
        ))}
        {buildValueGT(
          formVal.umbrella_subrogration_aggregate,
          franchisorData.umbrella_subrogration_aggregate,
          franchisorData.umbrella_subrogration_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> AGGREGATE {item.title}
          </div>
        ))}
      </div>

      <div className="form-main">
        <div className="panel-left">
          <div className="radio-container">
            <Radio.Group
              onChange={(evt) =>
                handleChange(
                  "umbrella_subrogration_liability",
                  evt.target.value
                )
              }
              value={formVal.umbrella_subrogration_liability}
            >
              <Radio value={FORM_UMBRELLA_LIABILITY}>UMBRELLA LIABILITY</Radio>
              <Radio value={FORM_EXCESS_LIABILITY}>EXCESS LIABILITY</Radio>
            </Radio.Group>
            {buildUmbrellaSubrogrationLiability(
              formVal.umbrella_subrogration_liability,
              franchisorData.umbrella_subrogration_liability,
              franchisorData.umbrella_subrogration_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>

          <div className="radio-container">
            <Radio.Group
              onChange={(evt) =>
                handleChange("umbrella_subrogration_activity", evt.target.value)
              }
              value={formVal.umbrella_subrogration_activity}
            >
              <Radio value={FORM_OCCURRENCE}>OCCURRENCE</Radio>
              <Radio value={FORM_CLAIMS_MADE}>CLAIMS-MADE</Radio>
            </Radio.Group>
            {buildUmbrellaSubrogrationActivity(
              formVal.umbrella_subrogration_activity,
              franchisorData.umbrella_subrogration_activity,
              franchisorData.umbrella_subrogration_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>

          <div className="radio-container">
            <Radio.Group
              onChange={(evt) =>
                handleChange("umbrella_subrogration_status", evt.target.value)
              }
              value={formVal.umbrella_subrogration_status}
            >
              <Radio value={FORM_DEDUCTIBLE}>DEDUCTIBLE</Radio>
              <Radio value={FORM_RETENTION}>RETENTION</Radio>

              <Radio value={FORM_NONE}>NONE</Radio>
            </Radio.Group>
            {buildUmbrellaSubrogrationStatus(
              formVal.umbrella_subrogration_status,
              franchisorData.umbrella_subrogration_status,
              franchisorData.umbrella_subrogration_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>

          <div className="alert-container">
            <h4>
              POLICY NUMBER: {formVal.umbrella_subrogration_policy_number}
            </h4>
            <h4>
              EFFECTIVE DATE: {formVal.umbrella_subrogration_effective_date}
            </h4>
            {buildEffectDate(
              formVal.umbrella_subrogration_effective_date,
              franchisorData.umbrella_subrogration_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
            <h4>
              EXPIRATION DATE: {formVal.umbrella_subrogration_expire_date}
            </h4>
            {buildExpireDate(
              formVal.umbrella_subrogration_expire_date,
              franchisorData.umbrella_subrogration_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
        <div className="panel-right">
          <div className="line-item-container">
            <Input
              title="EACH OCCURRENCE"
              placeholder="EACH OCCURRENCE"
              value={formVal.umbrella_subrogration_each_occurance}
              onChange={(evt) =>
                handleChange(
                  "umbrella_subrogration_each_occurance",
                  evt.target.value
                )
              }
            />
            {buildValueGT(
              formVal.umbrella_subrogration_each_occurance,
              franchisorData.umbrella_subrogration_each_occurance,
              franchisorData.umbrella_subrogration_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="line-item-container">
            <Input
              title="AGGREGATE"
              placeholder="AGGREGATE"
              value={formVal.umbrella_subrogration_aggregate}
              onChange={(evt) =>
                handleChange(
                  "umbrella_subrogration_aggregate",
                  evt.target.value
                )
              }
            />
            {buildValueGT(
              formVal.umbrella_subrogration_aggregate,
              franchisorData.umbrella_subrogration_aggregate,
              franchisorData.umbrella_subrogration_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
      </div>
      <div className="report-umbrella-content-mask"></div>
    </div>
  );
}
