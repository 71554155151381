import React from "react";
import _ from "lodash";
import { Input, Card } from "@components";
import { Button, Select } from "antd";
import { DeleteOutlined, HomeOutlined } from "@ant-design/icons";
import "./style.scss";

export default function BlockEquipment({
  data,
  locationList,
  onChange,
  disabled
}) {
  function handleChangeEquipmentItem(index, itemIndex, key, value) {
    const newFormVal = _.cloneDeep(data);
    newFormVal[index].equipmentItem[itemIndex][key] = value;
    onChange((prev) => newFormVal);
  }
  function handleChangeEquipmentInfo(index, key, value) {
    const newFormVal = _.cloneDeep(data);
    newFormVal[index][key] = value;
    onChange((prev) => newFormVal);
  }

  function handleAddNewLocation(index) {
    const newFormVal = _.cloneDeep(data);
    newFormVal.push({
      locationIndex: null,
      equipmentItem: []
    });
    onChange((prev) => newFormVal);
  }

  function handleDeleteLocation(index) {
    if (data.length === 0) return;
    const newFormVal = _.cloneDeep(data);
    newFormVal.splice(index, 1);
    onChange((prev) => newFormVal);
  }

  function handleAddNewEquipment(index) {
    const newFormVal = _.cloneDeep(data);
    newFormVal[index].equipmentItem.push({
      desc: "",
      year: "",
      make: "",
      model: "",
      serialNumber: "",
      value: ""
    });
    onChange((prev) => newFormVal);
  }

  function handleDeleteEquipment(index, itemIndex) {
    if (data.length === 0) return;
    const newFormVal = _.cloneDeep(data);
    newFormVal[index].equipmentItem.splice(itemIndex, 1);
    onChange((prev) => newFormVal);
  }

  function renderEquipmentInfo(index, equipment) {
    return (
      <div key={`equip-${index}`} className="block_eq-item">
        <div className="block_eq-item-con">
          <div className="block_eq-item-loc">
            <p className="input-title">
              Select A Physical Location (No Billing/Mailing Address)
            </p>
            <div className="block_eq-item-loc-select">
              <Select
                size="large"
                style={{ minWidth: "300px" }}
                options={locationList}
                value={equipment.locationIndex}
                onSelect={(evt) =>
                  handleChangeEquipmentInfo(index, "locationIndex", evt)
                }
              ></Select>
            </div>
          </div>
          {equipment.equipmentItem.map((equipItem, equipIndex) =>
            renderEquipmentItem(equipItem, index, equipIndex)
          )}
          <div className="block_eq-item-footer">
            {!disabled && (
              <div className="admin_franchisee_under-section-footer">
                <Button
                  className="admin_franchisee_under-section-footer-link"
                  onClick={() => handleAddNewEquipment(index)}
                >
                  Add Equipment
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="block_eq-item-action">
          {!disabled && (
            <Button
              className="block_eq-item-action-btn"
              onClick={() => handleDeleteLocation(index)}
            >
              <DeleteOutlined />
            </Button>
          )}
        </div>
      </div>
    );
  }

  function renderEquipmentItem(equipItem, index, itemIndex) {
    return (
      <div className="block_eq-item-sub">
        <div className="form-group-five-container">
          <Input
            title="Description"
            type="text"
            value={equipItem.desc}
            required
            onChange={(evt) =>
              handleChangeEquipmentItem(
                index,
                itemIndex,
                "desc",
                evt.target.value
              )
            }
          />
          <Input
            title="Year"
            type="text"
            value={equipItem.year}
            required
            inputType="TYPE_INPUT_YEAR"
            onChange={(evt) =>
              handleChangeEquipmentItem(
                index,
                itemIndex,
                "year",
                evt.target.value
              )
            }
          />
          <Input
            title="Make"
            type="text"
            value={equipItem.make}
            required
            onChange={(evt) =>
              handleChangeEquipmentItem(
                index,
                itemIndex,
                "make",
                evt.target.value
              )
            }
          />
          <Input
            title="Model"
            type="text"
            value={equipItem.model}
            required
            onChange={(evt) =>
              handleChangeEquipmentItem(
                index,
                itemIndex,
                "model",
                evt.target.value
              )
            }
          />
          <Input
            title="Serial Number"
            type="text"
            value={equipItem.serialNumber}
            required
            onChange={(evt) =>
              handleChangeEquipmentItem(
                index,
                itemIndex,
                "serialNumber",
                evt.target.value
              )
            }
          />
          <Input
            title="Value"
            size="large"
            value={equipItem.value}
            required
            inputType="TYPE_INPUT_CURRENCY"
            style={{ width: "100%" }}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
            onChange={(value) =>
              handleChangeEquipmentItem(index, itemIndex, "value", value)
            }
          />
        </div>
        <div className="block_eq-item-sub-action">
          {!disabled && (
            <Button
              className="block_eq-item-sub-action-btn"
              onClick={() => handleDeleteEquipment(index, itemIndex)}
            >
              <DeleteOutlined />
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="block_eq">
      {data.map((equipment, index) => renderEquipmentInfo(index, equipment))}
      {!disabled && (
        <div className="block_eq-footer">
          <Button
            className="block_eq-footer-link"
            onClick={handleAddNewLocation}
          >
            <HomeOutlined />
            Add Address
          </Button>
        </div>
      )}
    </div>
  );
}
