import React, { useState, useEffect } from "react";
import { Input } from "../../../../components";
import { Radio } from "antd";
import "./style.scss";
import {
  FORM_EXCLUDED,
  FORM_INCLUDED,
  FORM_LOSS_DISCOVERED,
  FORM_LOSS_SUSTAINED,
} from "../../../../assets/const/form";
import {
  buildEffectDate,
  buildExpireDate,
  buildValueGT,
  buildEpliThirdParty,
  buildValueLT,
} from "../../../../utils/build-logic";
import { STATUS_SECTION_APPROVE, DOC_MAX_LIMIT } from "@assets/const/status";
import { mapFieldEPLI } from "@utils/form-recog";

export default function EpliForm(props) {
  const { docIndex, franchisorData } = props;

  const [formVal, setFormVal] = useState(handleGetFormVal());

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    if (
      franchiseeData?.finalData?.epli_form_status === STATUS_SECTION_APPROVE
    ) {
      const finalData = franchiseeData.finalData;

      return {
        epli_occ: finalData.epli_occ,
        epli_agg: finalData.epli_agg,
        epli_third_party: finalData.epli_third_party,
        epli_wage: finalData.epli_wage,
        epli_mx: finalData.epli_mx,
        epli_policy_number: finalData.epli_policy_number,
        epli_effective_date: finalData.epli_effective_date,
        epli_expire_date: finalData.epli_expire_date,
      };
    }

    if (docIndex >= 0 && docIndex !== DOC_MAX_LIMIT) {
      const data = franchiseeData?.recogData[docIndex];
      if (data) {
        return mapFieldEPLI(data);
      }
    }

    return {};
  }

  const [loading, setLoading] = useState(false);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  return (
    <div className="report-epli-content">
      <div className="report-epli-content-analysis">
        <label>
          <b>Analysis:</b>
        </label>
        {buildEpliThirdParty(
          formVal.epli_third_party,
          franchisorData.epli_third_party,
          franchisorData.epli_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}

        {buildEffectDate(
          formVal.epli_effective_date,
          franchisorData.epli_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}

        {buildExpireDate(
          formVal.epli_expire_date,
          franchisorData.epli_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}

        {buildValueGT(
          formVal.epli_occ,
          franchisorData.epli_occ,
          franchisorData.epli_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}

        {buildValueGT(
          formVal.epli_agg,
          franchisorData.epli_agg,
          franchisorData.epli_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}

        {buildValueGT(
          formVal.epli_wage,
          franchisorData.epli_wage,
          franchisorData.epli_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}

        {buildValueLT(
          formVal.epli_mx,
          franchisorData.epli_mx,
          franchisorData.epli_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
      </div>

      <div className="form-main">
        <div className="panel-left">
          <div className="radio-container">
            <p className="input-title">3RD PARTY COVERAGE</p>
            <Radio.Group
              onChange={(evt) =>
                handleChange("epli_third_party", evt.target.value)
              }
              value={formVal.epli_third_party}
            >
              <Radio value={FORM_INCLUDED}>Included</Radio>
              <Radio value={FORM_EXCLUDED}>Excluded</Radio>
            </Radio.Group>
            {buildEpliThirdParty(
              formVal.epli_third_party,
              franchisorData.epli_third_party,
              franchisorData.epli_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>

          <div className="alert-container">
            <h4>POLICY NUMBER: {formVal.epli_policy_number}</h4>
            <h4>EFFECTIVE DATE: {formVal.epli_effective_date}</h4>
            {buildEffectDate(
              formVal.epli_effective_date,
              franchisorData.epli_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
            <h4>EXPIRATION DATE: {formVal.epli_expire_date}</h4>
            {buildExpireDate(
              formVal.epli_expire_date,
              franchisorData.epli_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
        <div className="panel-right">
          <div className="line-item-container">
            <Input
              title="EACH OCCURRENCE"
              placeholder="EACH OCCURRENCE"
              value={formVal.epli_occ}
              onChange={(evt) => handleChange("epli_occ", evt.target.value)}
            />
            {buildValueGT(
              formVal.epli_occ,
              franchisorData.epli_occ,
              franchisorData.epli_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="line-item-container">
            <Input
              title="AGGREGATE"
              placeholder="AGGREGATE"
              value={formVal.epli_agg}
              onChange={(evt) => handleChange("epli_agg", evt.target.value)}
            />
            {buildValueGT(
              formVal.epli_agg,
              franchisorData.epli_agg,
              franchisorData.epli_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>

          <div className="line-item-container">
            <Input
              title="WAGE & HOUR"
              placeholder="WAGE & HOUR"
              value={formVal.epli_wage}
              onChange={(evt) => handleChange("epli_wage", evt.target.value)}
            />
            {buildValueGT(
              formVal.epli_wage,
              franchisorData.epli_wage,
              franchisorData.epli_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>

          <div className="line-item-container">
            <Input
              title="MAX DEDUCTIBLE"
              placeholder="MAX DEDUCTIBLE"
              value={formVal.epli_mx}
              onChange={(evt) => handleChange("epli_mx", evt.target.value)}
            />
            {buildValueLT(
              formVal.epli_mx,
              franchisorData.epli_mx,
              franchisorData.epli_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
      </div>
      <div className="report-epli-content-mask"></div>
    </div>
  );
}
