import React, { useState } from "react";
import "./style.scss";
import { BlockEntity, BlockPOC } from "@components/underwriting";
import Card from "@components/card";

export default function QuoteEntityBlock({ data, onChange }) {
  return (
    <Card className="qu_entity" title="Business Entity">
      <BlockEntity data={data} onChange={onChange} />
    </Card>
  );
}
