import React, { useEffect, useState, useMemo } from "react";
import { Loading } from "../../../../components";
import { Select, Button, Alert, Switch } from "antd";
import "./style.scss";
import {
  DOCUMENT_STATUS_ARCHIVED,
  DOCUMENT_STATUS_REJECT
} from "../../../../assets/const/status";
import { WarningOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import { deleteAPI, get, put } from "../../../../utils/axios";

const { Option } = Select;

export default function CollapseWrapper(props) {
  const {
    children,
    title,
    recogData,
    fileUpload,
    docItemIndex,
    updateIndex,
    contactKey,
    contactFlag,
    franchiseeID,
    firstName,
    lastName
  } = props;

  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [contact, setContact] = useState(contactFlag);

  useEffect(() => {
    setContact(contactFlag);
  }, [contactFlag]);

  function handleChange(newValue) {
    updateIndex(newValue);
  }

  async function handleContact(checked) {
    if (franchiseeID) {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const result = await put(
          `contact/${franchiseeID}`,
          {
            [contactKey]: checked
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        await deleteAPI(`notification/delete/${franchiseeID}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        setLoading(false);
        setContact(checked);
      } catch (error) {
        setLoading(false);
      }
    }
  }

  return (
    <div className="collapse-section-container">
      <div className="collapse-section-container-header">
        <h3>{title}</h3>
        <div className="collapse-section-container-header-right">
          <div className="collapse-section-container-header-right-contact">
            <Switch checked={contact} onChange={handleContact} />
            <span>Contact ASAP</span>
          </div>
          {contact && (
            <WarningOutlined className="collapse-section-container-header-right-alert" />
          )}
          <Select
            defaultValue={"Please Select a Option"}
            style={{ width: 420 }}
            size="large"
            value={docItemIndex < 0 ? "Please Select a Option" : docItemIndex}
            onChange={handleChange}
          >
            {recogData &&
              recogData.map((recogDataItem, index) => {
                const disabled =
                  fileUpload[index].status === DOCUMENT_STATUS_ARCHIVED ||
                  fileUpload[index].status === DOCUMENT_STATUS_REJECT
                    ? true
                    : false;
                const docTitle = `${lastName}-${firstName}-COI-${moment(
                  fileUpload[index].upload_at
                ).format("DD-MM-YYYY")}`;
                return (
                  <Option value={index} disabled={disabled}>
                    {docTitle}
                  </Option>
                );
              })}
          </Select>
        </div>
      </div>
      {children}
      <Loading loading={loading} />
    </div>
  );
}
