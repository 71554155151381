import React from "react";
import { Modal, Button } from "antd";
import { format } from "date-fns";
import { APP_VERSION, RELEASE_NOTES } from "@assets/const/version";
import "./style.scss";

export default function ReleaseNote({ isOpen, onClose }) {
  return (
    <Modal
      open={isOpen}
      title="Release Notes"
      onOk={() => onClose(false)}
      onCancel={() => onClose(false)}
      footer={[
        <Button key="submit" type="primary" onClick={() => onClose(false)}>
          Close
        </Button>,
      ]}
    >
      <div className="release">
        {Object.entries(RELEASE_NOTES)
          .reverse()
          .map((item, mainIndex) => (
            <div key={`key-note-${mainIndex}`} className="release_note">
              <div className="release_note_header">
                <span className="release_note_header-version">
                  Version: {item[0]}
                </span>
                <span className="release_note_header-date">
                  {format(new Date(RELEASE_NOTES[item[0]].date), "LLL dd yyyy")}
                </span>
              </div>
              <ul className="release_note_content">
                {RELEASE_NOTES[item[0]].content.map((item, itemIndex) => (
                  <li key={`key-note-item-${mainIndex}-${itemIndex}`}>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          ))}
      </div>
    </Modal>
  );
}
