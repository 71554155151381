import React, { useState, useEffect } from "react";
import { Input } from "../../../../components";
import { Radio } from "antd";
import "./style.scss";
import {
  FORM_LOSS_DISCOVERED,
  FORM_LOSS_SUSTAINED,
} from "../../../../assets/const/form";
import {
  buildEffectDate,
  buildExpireDate,
  buildValueGT,
  buildCrimeForm,
} from "../../../../utils/build-logic";
import { STATUS_SECTION_APPROVE, DOC_MAX_LIMIT } from "@assets/const/status";
import { mapFieldCrime } from "@utils/form-recog";

export default function CrimeForm(props) {
  const { docIndex, franchisorData } = props;

  const [formVal, setFormVal] = useState(handleGetFormVal());

  useEffect(() => {
    setFormVal(handleGetFormVal());
  }, [docIndex]);

  function handleGetFormVal() {
    const { franchiseeData } = props;
    if (
      franchiseeData?.finalData?.crime_form_status === STATUS_SECTION_APPROVE
    ) {
      const finalData = franchiseeData.finalData;

      return {
        crime_each_claim: finalData.crime_each_claim,
        crime_third_party: finalData.crime_third_party,
        crime_form_type: finalData.crime_form_type,
        crime_policy_number: finalData.crime_policy_number,
        crime_effective_date: finalData.crime_effective_date,
        crime_expire_date: finalData.crime_expire_date,
      };
    }

    if (docIndex >= 0 && docIndex !== DOC_MAX_LIMIT) {
      const data = franchiseeData?.recogData[docIndex];
      if (data) {
        return mapFieldCrime(data);
      }
    }

    return {};
  }

  const [loading, setLoading] = useState(false);

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  return (
    <div className="report-crime-content">
      <div className="report-crime-content-analysis">
        <label>
          <b>Analysis:</b>
        </label>
        {buildCrimeForm(
          formVal.crime_form_type,
          franchisorData.crime_form_type,
          franchisorData.crime_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
        {buildEffectDate(
          formVal.crime_effective_date,
          franchisorData.crime_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
        {buildExpireDate(
          formVal.crime_expire_date,
          franchisorData.crime_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}

        {buildValueGT(
          formVal.crime_each_claim,
          franchisorData.crime_each_claim,
          franchisorData.crime_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}

        {buildValueGT(
          formVal.crime_third_party,
          franchisorData.crime_third_party,
          franchisorData.crime_required
        ).map((item) => (
          <div className="issue-item">
            <em>NON-COMPLIANT:</em> {item.title}
          </div>
        ))}
      </div>

      <div className="form-main">
        <div className="panel-left">
          <div className="radio-container">
            <Radio.Group
              onChange={(evt) =>
                handleChange("crime_form_type", evt.target.value)
              }
              value={formVal.crime_form_type}
            >
              <Radio value={FORM_LOSS_DISCOVERED}>Loss Discovered</Radio>
              <Radio value={FORM_LOSS_SUSTAINED}>Loss Sustained</Radio>
            </Radio.Group>
            {buildCrimeForm(
              formVal.crime_form_type,
              franchisorData.crime_form_type,
              franchisorData.crime_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>

          <div className="alert-container">
            <h4>POLICY NUMBER: {formVal.crime_policy_number}</h4>
            <h4>EFFECTIVE DATE: {formVal.crime_effective_date}</h4>
            {buildEffectDate(
              formVal.crime_effective_date,
              franchisorData.crime_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
            <h4>EXPIRATION DATE: {formVal.crime_expire_date}</h4>
            {buildExpireDate(
              formVal.crime_expire_date,
              franchisorData.crime_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
        <div className="panel-right">
          <div className="line-item-container">
            <Input
              title="EACH CLAIM"
              placeholder="EACH CLAIM"
              value={formVal.crime_each_claim}
              onChange={(evt) =>
                handleChange("crime_each_claim", evt.target.value)
              }
            />
            {buildValueGT(
              formVal.crime_each_claim,
              franchisorData.crime_each_claim,
              franchisorData.crime_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
          <div className="line-item-container">
            <Input
              title="3RD PARTY"
              placeholder="3RD PARTY"
              value={formVal.crime_third_party}
              onChange={(evt) =>
                handleChange("crime_third_party", evt.target.value)
              }
            />
            {buildValueGT(
              formVal.crime_third_party,
              franchisorData.crime_third_party,
              franchisorData.crime_required
            ).map((item) => (
              <div className="issue-report-item">{item.title}</div>
            ))}
          </div>
        </div>
      </div>
      <div className="report-crime-content-mask"></div>
    </div>
  );
}
